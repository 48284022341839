import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { DataTable } from 'carbon-components-react';

import Badges from '../Badges/Badges';

import LeavesContext from '../../../context/leavesContext';
import * as helper from '../../../utils/CoreHelpers';

import './RequestDetailsInfoTable.scss';

const { Table, TableRow, TableBody, TableCell, TableHeader } = DataTable;

function RequestHistoryTable({ historyArray, requestorFullName }) {
  /* eslint-disable-next-line react/prop-types */
  const HistoryActionRow = ({ dateString, children }) => (
    <TableRow>
      <TableHeader scope="rowheader" className="request-info-details__row-header">
        {dateString}
      </TableHeader>
      <TableCell>{children}</TableCell>
    </TableRow>
  );

  return historyArray.length > 0 ? (
    <div className="request-info-details__table-wrapper">
      <hr />
      <h2 className="request-info-details__heading bx--type-heading-02">History of this Request</h2>
      <Table size="small" shouldShowBorder className="request-info-details__table">
        <TableBody>
          {historyArray.map((item, index) => {
            const {
              historyDate,
              userFullName,
              action,
              commentForDepartment,
              commentForRequestor
            } = item;
            const historyDateMoment = moment
              .utc(historyDate)
              .tz(helper.getCurrentLocationTimezone());
            const isWeekAgo = moment().isAfter(historyDateMoment.clone().add(1, 'week'));
            let whenString = !isWeekAgo
              ? historyDateMoment.fromNow()
              : historyDateMoment.format('M/D/YY');
            const actionString = `${action.charAt(0).toUpperCase()}${action.slice(
              1
            )} by ${userFullName}`;
            let textStatus = '';

            if (moment().isBefore(historyDateMoment.clone().add(120, 'second'))) {
              whenString = 'Just now';
            }

            if (action === 'viewed' || action === 'cancelled') {
              textStatus = 'request-info-details__text-status--viewed';
            }

            if (action === 'approved') {
              textStatus = 'request-info-details__text-status--approved';
            }

            if (action === 'rejected') {
              textStatus = 'request-info-details__text-status--rejected';
            }

            return (
              <React.Fragment key={btoa(index)}>
                <HistoryActionRow dateString={whenString}>
                  <span className={textStatus}>{actionString}</span>
                </HistoryActionRow>
                {commentForRequestor !== '' && (
                  <HistoryActionRow dateString={whenString}>
                    <span>
                      {`Comment from ${userFullName} for ${requestorFullName.split(' ')[0]}:`}
                    </span>
                    <span className="request-info-details__status-comment">
                      {`"${commentForRequestor}"`}
                    </span>
                  </HistoryActionRow>
                )}
                {commentForDepartment !== '' && (
                  <HistoryActionRow dateString={whenString}>
                    <span>{`Comment from ${userFullName} for approval team:`}</span>
                    <span className="request-info-details__status-comment">
                      {`"${commentForDepartment}"`}
                    </span>
                  </HistoryActionRow>
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </div>
  ) : null;
}

const RequestDetailsTable = ({ requestDetails }) => {
  const teamMemberNames = requestDetails.relevantTeamMembersFullName;

  function getSingleOrDateRange(fromDate, toDate, workDays, halfDayPeriod, datesJson) {
    if (!fromDate) return null;

    let headerString = 'Date:';
    let dateString;
    let fromDateFormat = 'M/D';
    const toDateValue = toDate || null;

    const fromDateMoment = moment(fromDate);

    if ((fromDateMoment && !toDateValue) || (workDays > 0 && workDays <= 1)) {
      fromDateFormat = 'ddd, M/D/YY';

      dateString = fromDateMoment.format(fromDateFormat);
    } else if (fromDateMoment && toDateValue) {
      const toDateMoment = moment(toDateValue);

      if (fromDateMoment.year() !== toDateMoment.year()) {
        fromDateFormat += '/YY';
      }

      dateString = fromDateMoment.format(fromDateFormat);

      if (!fromDateMoment.isSame(toDateMoment)) {
        dateString += ` → ${toDateMoment.format('M/D/YY')}`;

        headerString = 'Date Range:';
      }
    }

    let halfDayPeriodString = '';
    let workDaysHtml = '';

    if (workDays > 0 && workDays <= 1) {
      halfDayPeriodString = halfDayPeriod ? ` (${halfDayPeriod.toLowerCase()} only)` : '';
    }

    dateString += halfDayPeriodString;

    if (workDays > 1) {
      const currentYear = new Date().getFullYear();
      const dates = datesJson;

      workDaysHtml = (
        <ul style={{ listStyleType: 'initial', marginLeft: '20px' }}>
          {dates.map((item, index) => {
            const workDayDate = moment(item);
            let dateStringFormat = 'ddd, M/D';

            if (workDayDate.year() !== currentYear) {
              dateStringFormat += '/YY';
            }

            return <li key={btoa(index)}>{`${workDayDate.format(dateStringFormat)}`}</li>;
          })}
        </ul>
      );
    }

    return (
      <React.Fragment>
        <TableRow>
          <TableHeader scope="rowheader" className="request-info-details__row-header">
            {headerString}
          </TableHeader>
          <TableCell>
            {dateString}
            {workDays > 1 ? (
              <span style={{ display: 'block' }}>{`(${workDays} Work Days)`}</span>
            ) : null}
          </TableCell>
        </TableRow>
        {workDays > 1 ? (
          <TableRow className="request-info-details__info">
            <TableHeader scope="rowheader" className="request-info-details__row-header">
              Work Days:
            </TableHeader>
            <TableCell>{workDaysHtml}</TableCell>
          </TableRow>
        ) : null}
      </React.Fragment>
    );
  }

  return (
    <div className="request-info-details__table-wrapper">
      <hr />
      <h2 className="request-info-details__heading">This Request</h2>
      <Table size="small" shouldShowBorder className="request-info-details__table">
        <TableBody>
          <TableRow>
            <TableHeader scope="rowheader" className="request-info-details__row-header">
              Requestor:
            </TableHeader>
            <TableCell>{requestDetails.requestorFullName}</TableCell>
          </TableRow>
          <TableRow>
            <TableHeader scope="rowheader" className="request-info-details__row-header">
              Type:
            </TableHeader>
            <TableCell>{requestDetails.requestType}</TableCell>
          </TableRow>
          {// Get Date or Date Range string with Work Days.
          getSingleOrDateRange(
            requestDetails.fromDate,
            requestDetails.toDate,
            requestDetails.workDays,
            requestDetails.halfDayPeriod,
            requestDetails.datesJson
          )}
          <TableRow>
            <TableHeader scope="rowheader" className="request-info-details__row-header">
              Reason:
            </TableHeader>
            <TableCell>{requestDetails.reason}</TableCell>
          </TableRow>
          {requestDetails.requestorComments.length > 0 && (
            <TableRow>
              <TableHeader scope="rowheader" className="request-info-details__row-header">
                Requestor Comments:
              </TableHeader>
              <TableCell>{requestDetails.requestorComments}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableHeader scope="rowheader" className="request-info-details__row-header">
              Lead Producers:
            </TableHeader>
            <TableCell>{helper.formatListOfNames(teamMemberNames)}</TableCell>
          </TableRow>
          <TableRow>
            <TableHeader scope="rowheader" className="request-info-details__row-header">
              Submitted:
            </TableHeader>
            <TableCell>
              {moment
                .utc(requestDetails.submitted)
                .tz(helper.getCurrentLocationTimezone())
                .format('ddd, MM/DD/YY hh:mm A')}{' '}
              {moment.tz(helper.getCurrentLocationTimezone()).zoneAbbr()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHeader scope="rowheader" className="request-info-details__row-header">
              Reference:
            </TableHeader>
            <TableCell>{requestDetails.requestRefNum}</TableCell>
          </TableRow>
          <TableRow>
            <TableHeader scope="rowheader" className="request-info-details__row-header">
              Status:
            </TableHeader>
            <TableCell>
              <Badges text={requestDetails.requestStatusString} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

function RequestDetailsInfoTable() {
  const { state } = useContext(LeavesContext);

  function getFullHistoryArray() {
    const submittedAction = {
      action: 'request submitted',
      commentForDepartment: '',
      commentForRequestor: '',
      eventEmail: state.requestInfo.requestorEmail,
      historyDate: state.requestInfo.submitted,
      requestFlowStep: '0',
      userFullName: state.requestInfo.requestorFullName
    };

    return [submittedAction, ...state.requestInfo.historyArray];
  }

  return (
    <div className="request-info-details">
      <RequestDetailsTable requestDetails={state.requestInfo} />
      <RequestHistoryTable
        historyArray={getFullHistoryArray()}
        requestorFullName={state.requestInfo.requestorFullName}
      />
    </div>
  );
}

RequestHistoryTable.propTypes = {
  historyArray: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  requestorFullName: PropTypes.string
};

RequestHistoryTable.defaultProps = {
  historyArray: [{}],
  requestorFullName: ''
};

RequestDetailsTable.propTypes = {
  requestDetails: PropTypes.objectOf(PropTypes.arrayOf).isRequired
};

export default RequestDetailsInfoTable;
