/* eslint-disable import/prefer-default-export */
const grailURL = process.env.APP_API_URL || 'https://grail.codeandtheory.net';

export const vars = {
  GA_ID: 'UA-91795-6',
  SYSTEM_NAME: 'Responsible Time Off',
  FORM_ADMIN_EMAIL: 'rto.request.system.admin@codeandtheory.com',
  GRAIL_TIMESHEET_URL: `${grailURL}/timesheets`,
  GRAIL_RESOURCING_URL: `${grailURL}/timesheets`,
  CALENDAR_ID: {
    Concept: 'codeandtheory.com_4sg06m4s4g30oudbbu7p9tlqlc@group.calendar.google.com',
    'Client Engagement': 'codeandtheory.com_u247t26i551hmod1lnvq64kbrs@group.calendar.google.com',
    'Creative Strategy': 'codeandtheory.com_ojeuiov0bhit2k17g8d6gj4i68@group.calendar.google.com',
    'Design, Copy & Concept':
      'codeandtheory.com_ab1roaddd9s4mdesmse5pal01c@group.calendar.google.com',
    Enterprise: 'codeandtheory.com_g9911mmj4tln2amlurlslvjbu4@group.calendar.google.com',
    'Quality Assurance': 'codeandtheory.com_07ihj63m0u7t1bafhkkgiq0qtk@group.calendar.google.com',
    Technology: 'codeandtheory.com_gupefi0no9i6l0s42kipbe2jfk@group.calendar.google.com',
    SF: 'codeandtheory.com_il64bdhhpnjjj55gjha1ceqg8k@group.calendar.google.com',
    Adidas: 'codeandtheory.com_3i79qi9lpu84bbfeeja5uqrnko@group.calendar.google.com'
  }
};
