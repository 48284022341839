/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import React, { useState, useContext, useEffect, useReducer, useRef } from 'react';
import { Link } from '@reach/router';
import { DataTable, TooltipIcon, Loading } from 'carbon-components-react';
import IconWarningFilled16 from '@carbon/icons-react/es/warning--filled/16';

import { format } from 'date-fns';
import UserContext from '../../../context/userContext';
import LeavesContext, { initialState } from '../../../context/leavesContext';

import Badges from '../Badges/Badges';
import RTODataTable from '../RTODataTable/RTODataTable';
import RTODataTableFilters from '../RTODataTableFilters/RTODataTableFilters';

import './SummaryEmployeeTable.scss';
import { getSubordinatesApi } from '../../../utils/APIs';
import LeavesReducer from '../../../context/leavesReducer';
import * as helper from '../../../utils/CoreHelpers';
// import { containerCSS } from 'react-select/dist/chunk-4c778abc.cjs.prod';

const { TableHeader, TableRow, TableCell } = DataTable;

const defaultSortDetails = {
  sortDirection: 'ASC',
  key: 'fullName'
};

function SummaryEmployeeTable(props) {
  const [currentEmployees, setCurrentEmployees] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentFilters, setCurrentFilters] = useState({});
  const [totalRequestItems, settotalRequestItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeItem, setPageSizeItem] = useState(0);
  const [searchQuery, setSearchQuery] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortDetails, setSortDetails] = useState(defaultSortDetails);
  const { state } = useContext(LeavesContext);
  const { state: userState } = useContext(UserContext);
  const { location, uri } = props;
  const [leaveState, dispatch] = useReducer(LeavesReducer, initialState);
  const user = userState.isImpersonatingUser ? userState.asUser : userState.user;
  const { Email } = user;
  const inputTimeoutRef = useRef(null);
  const TIMEOUT_MS = 300;
  const tableHeaders = () => {
    let headerNames;
    if (uri === '/hr') {
      headerNames = [
        { key: 'fullName', header: 'Employee' },
        { key: 'total_vacation', header: 'Vacation - Total' },
        { key: 'days_used', header: 'Vacation', textAppend: ' - Days Used Already' },
        { key: 'days_upcoming', header: 'Vacation', textAppend: ' - Upcoming Days' },
        {
          key: 'days_pending_approval',
          header: 'Vacation',
          textAppend: ' - Days Awaiting Approval'
        },
        { key: 'sick', header: 'Sick/Emergency' },
        { key: 'total_vacation_sick', header: 'Total Vacation + Sick/Emergency' },
        { key: 'remote', header: 'Work From Home' },
        { key: 'parental', header: 'Parental Leave' },
        { key: 'bereavement', header: 'Bereavement' },
        { key: 'jury', header: 'Jury Duty' }
      ];
    } else {
      headerNames = [
        { key: 'fullName', header: 'Employee' },
        { key: 'vacation', header: 'Vacation' },
        { key: 'sick', header: 'Sick/Emergency' },
        { key: 'total_vacation_sick', header: 'Total Vacation + Sick/Emergency' },
        { key: 'remote', header: 'Work From Home' },
        { key: 'parental', header: 'Parental Leave' },
        { key: 'bereavement', header: 'Bereavement' },
        { key: 'jury', header: 'Jury Duty' }
      ];
    }
    return headerNames;
  };
  const colspanTotal = tableHeaders().length;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setCurrentEmployees([]);
      paginationChange({ page: currentPage, pageSize: pageSizeItem });
    }
  }, [sortDetails]);

  useEffect(() => {
    // set page size and total page info on table on state change
    const { pagination } = state.subordinates || {};

    settotalRequestItems(pagination ? pagination.total_items : 1);
    setPageSizeItem(pagination ? pagination.page_sizes : 20);
  }, [state]);

  useEffect(() => {
    // set datatable on leaveState change
    const { employees = [] } = leaveState.subordinates || {};
    if (employees.length > 0) setCurrentEmployees(processRows(employees));
    else setCurrentEmployees(undefined);
  }, [leaveState]);

  useEffect(() => {
    // trigger pagination module update on filter change
    if (isMounted) {
      paginationChange({ page: currentPage, pageSize: pageSizeItem });
    }
  }, [currentFilters, searchQuery]);

  function getCurrentTab() {
    let tab = 'admin';

    if (uri === '/vacation-approver') {
      tab = 'vacation_approver';
    } else if (uri === '/department-lead') {
      tab = 'department_lead';
    } else if (uri === '/hr') {
      tab = 'hr';
    }

    return tab;
  }

  async function getSubordinates(return_all = 1, page = 1, pageSize = 20) {
    const tab = getCurrentTab();

    setIsLoading(true);

    const response = await getSubordinatesApi(
      Email,
      tab,
      page,
      pageSize,
      return_all,
      sortDetails.sortDirection,
      sortDetails.key,
      {
        year:
          currentFilters.year ||
          (currentFilters.year === '' ? 'all years' : new Date().getFullYear().toString()),
        from_date: format(currentFilters.from_date || helper.getDefaultWeekDay(), 'YYYY-MM-DD'),
        to_date: format(currentFilters.to_date || helper.getDefaultWeekDay('last'), 'YYYY-MM-DD'),
        division: currentFilters.division || 'all divisions',
        department: currentFilters.department || 'all departments',
        status: currentFilters.status || (currentFilters.status === '' ? 'all status' : 'active'),
        search: searchQuery
      }
    );

    return response;
  }

  async function paginationChange({ page, pageSize }) {
    const response = await getSubordinates(1, page, pageSize);

    if (response) {
      dispatch({ type: 'GET_SUBORDINATES', payload: response.data });

      settotalRequestItems(response.data.pagination.total_items);
      setPageSizeItem(response.data.pagination.page_sizes);
      setCurrentPage(page);

      if (Object.values(filters).length === 0) {
        setFilters(response.data.summaryTableFilters);
      }

      setIsLoading(false);
    }
  }

  function processRows(list) {
    const employees = list !== undefined ? list : null;
    return processSubordinateData(employees);
  }

  function processSubordinateData(subordinatesArray) {
    if (!subordinatesArray || subordinatesArray.length === 0) return null;

    const subordinateSummary = subordinatesArray.map(subordinate => {
      const { counts, ...subordinateData } = subordinate;
      const leavesSummary = processLeavesSummaryData(counts);

      return {
        ...subordinateData,
        ...leavesSummary
      };
    });

    return subordinateSummary;
  }

  function processLeavesSummaryData(leaveCounts) {
    if (!leaveCounts) return null;

    const leaveTypesWithCount = {
      vc: { approved: 0, upcoming: 0, pending: 0 },
      rm: { approved: 0, upcoming: 0, pending: 0 },
      sc: { approved: 0, upcoming: 0, pending: 0 },
      pl: { approved: 0, upcoming: 0, pending: 0 }, // Parental Leave
      bv: { approved: 0, upcoming: 0, pending: 0 }, // Bereavement
      ju: { approved: 0, upcoming: 0, pending: 0 } // Jury Duty
    };
    const { vc, rm, sc, pl, bv, ju } = leaveTypesWithCount;

    const {
      'Sick/Emergency': sickObj,
      Vacation: vacationObj,
      'Work From Home': remoteObj,
      'Parental Leave': parentalObj,
      Bereavement: bereavementObj,
      'Jury Duty': juryObj
    } = leaveCounts;

    vc.approved += vacationObj.approved.past;
    vc.upcoming += vacationObj.approved.upcoming;
    vc.pending += vacationObj.pending.total;
    rm.approved += remoteObj.approved.past;
    rm.upcoming += remoteObj.approved.upcoming;
    rm.pending += remoteObj.pending.total;
    sc.approved += sickObj.approved.past;
    sc.upcoming += sickObj.approved.upcoming;
    sc.pending += sickObj.pending.total;
    pl.approved += parentalObj.approved.past;
    pl.upcoming += parentalObj.approved.upcoming;
    pl.pending += parentalObj.pending.total;
    bv.approved += bereavementObj.approved.past;
    bv.upcoming += bereavementObj.approved.upcoming;
    bv.pending += bereavementObj.pending.total;
    ju.approved += juryObj.approved.past;
    ju.upcoming += juryObj.approved.upcoming;
    ju.pending += juryObj.pending.total;

    const leavesSummary = {
      vacation: JSON.stringify(vc), // total vc object
      total_vacation: JSON.stringify({
        approved: vc.approved + vc.upcoming + vc.pending
      }),
      days_used: JSON.stringify({
        approved: vc.approved
      }),
      days_upcoming: JSON.stringify({
        approved: vc.upcoming
      }),
      days_pending_approval: JSON.stringify({
        approved: vc.pending
      }),
      remote: JSON.stringify(rm),
      sick: JSON.stringify(sc),
      total_vacation_sick: JSON.stringify({
        approved: vc.approved + sc.approved,
        upcoming: vc.upcoming + sc.upcoming,
        pending: vc.pending + sc.pending
      }),
      parental: JSON.stringify(pl),
      bereavement: JSON.stringify(bv),
      jury: JSON.stringify(ju)
    };

    return leavesSummary;
  }

  function handleTableFilter(filterData) {
    setCurrentFilters(filterData);

    if (!isMounted) {
      setCurrentFilters(prev => ({
        ...prev,
        status: 'active'
      }));
    }

    return '';
  }

  function handleTableSearch(event) {
    const inputValue = event.target.value;

    clearTimeout(inputTimeoutRef.current);

    inputTimeoutRef.current = setTimeout(() => {
      setSearchQuery(inputValue);
    }, TIMEOUT_MS);
  }

  function processCsvData(data) {
    const employees = data !== undefined ? data : [];

    const csv = [];

    // CSV header
    csv.push(
      tableHeaders().map(i => {
        const getheader = i.textAppend ? `${i.header}${i.textAppend}` : i.header;
        return getheader;
      })
    );

    const processLeaveCount = json => {
      const { approved, upcoming, pending } = JSON.parse(json);

      let leave = approved;

      if (upcoming && upcoming !== 0) leave += ` (+${upcoming}) `;
      if (pending && pending !== 0) leave += ` +${pending}`;

      return leave;
    };

    csv.push(
      ...employees.map(e => [
        e.fullName,
        processLeaveCount(e.total_vacation),
        processLeaveCount(e.days_used),
        processLeaveCount(e.days_upcoming),
        processLeaveCount(e.days_pending_approval),
        processLeaveCount(e.sick),
        processLeaveCount(e.total_vacation_sick),
        processLeaveCount(e.remote),
        processLeaveCount(e.parental),
        processLeaveCount(e.bereavement),
        processLeaveCount(e.jury)
      ])
    );

    return csv;
  }

  async function getCsvData() {
    const response = await getSubordinates(0);

    if (response) {
      if (response.data.employees.length > 0)
        return processCsvData(processRows(response.data.employees));
    }

    return [];
  }

  const customSort = (cellA, cellB, { sortDirection, sortStates, key }) => {
    const getHeaderValues = tableHeaders().map(i => i.key);

    if (sortDetails.sortDirection !== sortDirection || sortDetails.key !== key) {
      setSortDetails({ sortDirection, key });
    }

    if (sortDetails.sortDirection !== sortDirection || sortDetails.key !== key) {
      setSortDetails({ sortDirection, key });
    }

    if (getHeaderValues.includes(key) && key !== 'fullName') {
      const { approved: approvedA } = JSON.parse(cellA);
      const { approved: approvedB } = JSON.parse(cellB);

      if (sortDirection === sortStates.DESC) {
        return approvedB - approvedA;
      }

      return approvedA - approvedB;
    }

    // Default sorting method
    if (sortDirection === sortStates.DESC) {
      return cellB.localeCompare(cellA);
    }

    return cellA.localeCompare(cellB);
  };

  const Description = () => (
    <>
      <span>
        {`Click on an employee's name to show the details of their requests.`}
        {location.pathname !== '/vacation-approver' && user.Role.search(/Admin|HR/) > -1
          ? ' Click the ⧉ icon to impersonate an employee and submit RTO dates as them (with the option to suppress email notifications)'
          : ''}
      </span>
      <span style={{ display: 'block' }}>
        <span>Key: </span>
        <span>Days used already </span>
        <span style={{ color: '#888888' }}>(+ upcoming days) </span>
        <Badges type="warning" text="+ days awaiting approval" />
      </span>
    </>
  );

  const FilterRender = (
    <RTODataTableFilters
      filters={filters}
      selectChange={event => handleTableFilter(event)}
      getTab={getCurrentTab()}
      getCsvData={() => getCsvData()}
    />
  );

  const tooltipProps = () => ({
    direction: 'bottom',
    align: 'start',
    tooltipText: 'This user is not in any Department Group. Please update Grail accordingly.'
  });

  return (
    <RTODataTable
      title="Summary by Employee"
      description={<Description />}
      paginationChange={event => paginationChange(event)}
      totalRequestItems={totalRequestItems}
      pageSizeItem={pageSizeItem}
      ToolbarExtraComponents={FilterRender}
      tableHeaders={tableHeaders()}
      tableRows={currentEmployees}
      withSearch
      customSearchFunction={event => handleTableSearch(event)}
      withSorting
      customSort={customSort}
      headersRender={(headers, getHeaderProps) =>
        headers.map(header => {
          return (
            <TableHeader key={header.key} {...getHeaderProps({ header })}>
              {uri === '/hr' && header.textAppend ? (
                <span style={{ color: '#525252', fontWeight: '400' }}>
                  {header.header} {header.textAppend}
                </span>
              ) : (
                <span>{header.header}</span>
              )}
            </TableHeader>
          );
        })
      }
      rowsRender={rows => {
        return rows.length > 0 ? (
          rows.map(row => {
            const rawData = currentEmployees?.find(employee => row.id === employee.id); // also checks if currentEmployees is empty or not

            if (!rawData) return null;

            return (
              <TableRow key={row.id}>
                {row.cells.map(cell => {
                  if (cell.id === `${row.id}:fullName`) {
                    const { fullName, has_discipline } = rawData;
                    const currentLocState = location && location.state;
                    const stateToPass = {
                      ...currentLocState,
                      fullName
                    };
                    const showToolTip = has_discipline === false;

                    return (
                      <TableCell key={cell.id}>
                        {showToolTip && (
                          <TooltipIcon
                            className="no-discipline-lead"
                            style={{ marginRight: '5px' }}
                            {...tooltipProps()}
                          >
                            <IconWarningFilled16 />
                          </TooltipIcon>
                        )}
                        <Link to={`employee/${rawData.email}`} state={stateToPass}>
                          {cell.value}
                        </Link>
                        {location.pathname !== '/vacation-approver' &&
                          (user.Role === 'Admin' || user.Role === 'HR') && (
                            <Link
                              to={`/existing-requests/impersonate/${rawData.email}`}
                              state={{ impersonateUser: rawData.email }}
                              title={`Click to impersonate ${cell.value}`}
                              style={{ marginLeft: '5px' }}
                            >
                              ⧉
                            </Link>
                          )}
                      </TableCell>
                    );
                  }

                  const { approved, upcoming, pending } = JSON.parse(cell.value);

                  return (
                    <TableCell key={cell.id}>
                      <span
                        style={{
                          color: approved === 0 ? '#999999' : ''
                        }}
                      >
                        {approved}
                      </span>
                      {upcoming && upcoming !== 0 ? (
                        <span style={{ color: '#888888' }}>{` (+${upcoming}) `}</span>
                      ) : (
                        ' '
                      )}
                      {pending && pending !== 0 ? (
                        <Badges type="warning" text={`+${pending}`} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={colspanTotal}>
              {isLoading ? (
                <div className="loading-animation">
                  <Loading small withOverlay={false} />
                </div>
              ) : (
                'No employees found'
              )}
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}

export default SummaryEmployeeTable;
