/* eslint-disable react/prop-types */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import { Link, navigate } from '@reach/router';
import Close20 from '@carbon/icons-react/es/close/20';
import UserContext from '../../../context/userContext';

import './Header.scss';

const Logged = () => {
  const { state, dispatch } = useContext(UserContext);

  function handleLogout() {
    dispatch({ type: 'LOGOUT_USER' });
    navigate('/login');
  }
  return (
    <div className="logged">
      {state.isImpersonatingUser ? (
        <div className="logged__as">
          <span className="logged__as-text">{`Impersonating ${state.asUser['Full Name']}`}</span>
          <Link to="/" title="End impersonation">
            <Close20 />
          </Link>
        </div>
      ) : (
        <>
          <span className="logged__name">{state.user['Full Name']}</span>
          <br />
          <span className="logged__email">{state.user.Email}</span>
        </>
      )}
      {state.isLogged && (
        <button
          type="button"
          title="Logout"
          className="logged__logout"
          onClick={() => handleLogout()}
        >
          <span>Logout</span>
        </button>
      )}
    </div>
  );
};

function Header() {
  const rootClass = `header header--${process.env.APP_ENV || 'development'}`;
  return (
    <div className={rootClass}>
      <div className="header__wrapper">
        <div className="branding">
          <div className="branding__logo">Code and Theory</div>
          <h1 className="branding__title">Responsible Time Off</h1>
        </div>
        <Logged />
      </div>
    </div>
  );
}

export default Header;
