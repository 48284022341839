/* eslint-disable react/no-danger */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import IconCheckmark from '@carbon/icons-react/es/checkmark/24';
import IconClose from '@carbon/icons-react/es/close/24';
import IconWarning from '@carbon/icons-react/es/warning--alt--filled/24';

import './Alerts.scss';

/**
 * Alert Module
 *
 * @param {*} { type, message }
 * @returns
 */

function Alerts({ type, message, noIcon, children, active }) {
  let alertIcon = '';
  let isDefaultAlert = false;

  function alertClasses() {
    switch (type) {
      case 'approved':
      case 'success':
        alertIcon = <IconCheckmark />;
        return 'alert--success';
      case 'rejected':
      case 'danger':
        alertIcon = <IconClose />;
        return 'alert--danger';
      case 'warning':
      case 'info':
        alertIcon = <IconWarning />;
        return 'alert--warning';
      default:
        isDefaultAlert = true;
        return 'alert--secondary';
    }
  }

  return (
    <div className={cx(`alert`, alertClasses(), { 'bx--visually-hidden': !active })}>
      <div className="alert__wrapper">
        {!isDefaultAlert && !noIcon && <div>{alertIcon}</div>}
        <div>
          <p dangerouslySetInnerHTML={{ __html: message }} />
          {isDefaultAlert && (
            <p>
              If you have feedback or experience any issues with the form, please{' '}
              <a href="mailto:rto.request.system.admin@codeandtheory.com" title="Send an Email">
                email the form administrator
              </a>
              . Please include as much detail as possible, including screenshots, URL(s), the
              browser/OS used, and any error messages received.
            </p>
          )}
          {children && children}
        </div>
      </div>
    </div>
  );
}

Alerts.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  noIcon: PropTypes.bool,
  children: PropTypes.node,
  active: PropTypes.bool
};

Alerts.defaultProps = {
  type: 'default',
  message: 'Sample alert message',
  noIcon: false,
  children: '',
  active: true
};

export default Alerts;
