/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Select, SelectItem, FormLabel } from 'carbon-components-react';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import moment from 'moment-timezone';
import * as helper from '../../../utils/CoreHelpers';

import './RTODataTableFilters.scss';

import RTODatePicker from '../../generic/DatePicker/DatePicker';

// Set default value for filter options aside from "all options".
const filterKeyMap = {
  year: new Date().getFullYear().toString(),
  status: 'active'
};

// Set default filters to display per tab
const defaultFiltersByTab = {
  hr: ['from_date', 'to_date', 'division', 'department', 'status'],
  admin: ['year', 'division', 'department', 'status'],
  vacation_approver: ['year', 'status'],
  department_lead: ['year', 'status']
};

// Set default filter values
const defaultFilters = {
  year: ['all', new Date().getFullYear().toString()],
  from_date: { min_date: null, max_date: null },
  to_date: { max_date: null },
  division: ['all'],
  department: ['all'],
  status: ['active', 'inactive']
};

const RTODataTableFilters = props => {
  const [filterValues, setFilterValues] = useState({});
  const [csvFileName, setCsvFileName] = useState();
  const [csvData, setCsvData] = useState([]);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [loadedFilters, setLoadedFilters] = useState(defaultFilters);
  const [isLoaded, setIsLoaded] = useState(false);
  const { filters, selectChange, getCsvData, getTab = 'admin' } = props;
  const filterKeys = Object.keys(loadedFilters);
  const filterItems = Object.values(loadedFilters);
  const hideClearButton = filterKeys.length === 1 && filterKeys.includes('year');
  const firstWeekDayCurrentYear = helper.getDefaultWeekDay();
  const lastWeekDayCurrentYear = helper.getDefaultWeekDay('last');
  const csvEl = useRef();

  function setFilterValue(key, value) {
    const currentFilters = { ...filterValues };
    currentFilters[key] = value;
    setFilterValues(currentFilters);
  }

  function setDefaultFilterValues() {
    // Manually set the year, status, from_date, and to_date filter values to current year, active status, and first/last weekday of the year, respectively.
    // The Select's defaultValue does not trigger onChange and does not update state value upwards.
    const values = {};

    filterKeys.forEach(k => {
      if (filterValues[k] !== undefined) return;

      if (k === 'year') {
        values.year = new Date().getFullYear().toString();
      }
      if (k === 'status') {
        values.status = 'active';
      }
      if (k === 'from_date') {
        values.from_date = firstWeekDayCurrentYear;
      }
      if (k === 'to_date') {
        values.to_date = lastWeekDayCurrentYear;
      }
    });

    if (Object.keys(values).length > 0) setFilterValues(values);
  }

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      setLoadedFilters(filters);
      setIsLoaded(true);
    }
  }, [filters]);

  useEffect(() => {
    if (isLoaded) setDefaultFilterValues();
  }, [loadedFilters]);

  useEffect(() => {
    selectChange(filterValues);
  }, [filterValues]);

  useEffect(() => {
    if (csvData.length > 0 && isCsvLoading)
      setTimeout(() => {
        csvEl.current.link.click();
        setIsCsvLoading(false);
      });
  }, [csvData]);

  return (
    <Form className="rto-data-table__filters">
      {getTab === 'hr' && loadedFilters.from_date && (
        <div className="rto-data-table__filter-datepickers">
          <div className="rto-data-table__filter-datepicker">
            <FormLabel
              htmlFor="dropdown-from_date"
              className="bx--label required-field filter-datepicker-label"
            >
              From
            </FormLabel>
            <RTODatePicker
              id="dropdown-from_date"
              className="datepicker-field"
              value={filterValues.from_date ? filterValues.from_date : firstWeekDayCurrentYear}
              minDate={moment(loadedFilters.from_date.min_date).toDate()}
              maxDate={moment(loadedFilters.from_date.max_date).toDate()}
              onChange={e => setFilterValue('from_date', e)}
              disabled={!isLoaded}
            />
          </div>
          <div className="rto-data-table__filter-datepicker">
            <FormLabel
              htmlFor="dropdown-to_date"
              className="bx--label required-field filter-datepicker-label"
            >
              To
            </FormLabel>
            <RTODatePicker
              id="dropdown-to_date"
              className="datepicker-field"
              value={filterValues.to_date ? filterValues.to_date : lastWeekDayCurrentYear}
              minDate={filterValues.from_date}
              maxDate={moment(loadedFilters.to_date.max_date).toDate()}
              disabled={!isLoaded}
              placeholderText="Select Date"
              onChange={e => setFilterValue('to_date', e)}
            />
          </div>
        </div>
      )}

      {filterKeys.map((filterKey, index) => {
        const filterOptions = filterItems[index];

        if (
          filterKey === 'from_date' ||
          filterKey === 'to_date' ||
          (getTab === 'hr' && filterKey === 'year')
        )
          return null;

        if (isLoaded) {
          if (filterOptions.length <= 1 && filterKey !== 'year') return null;
        } else if (!defaultFiltersByTab[getTab].includes(filterKey)) return null;

        if (filterOptions.length > 1 && !filterOptions.includes('all')) {
          filterOptions.unshift('all');
        }

        if (filterKey === 'year') {
          if (filterOptions.length < 1) filterOptions.push(filterKeyMap.year);

          filterOptions.sort().reverse();
        }

        return (
          <Select
            key={filterKey}
            id={`select-${filterKey}`}
            className="rto-data-table__filter"
            defaultValue={filterKeyMap[filterKey] || ''}
            labelText={`${filterKey.charAt(0).toUpperCase() + filterKey.slice(1)}`}
            inline
            onChange={e => {
              const selectId = e.target.id.replace('select-', '');

              setFilterValue(selectId, e.target.value);
            }}
            disabled={!isLoaded}
          >
            {filterOptions.map(option => {
              let text = option;

              if (option === 'all' && filterKey !== 'status') {
                text = `All ${filterKey}s`;
              } else if (option === 'all' && filterKey === 'status') {
                text = `All ${filterKey}`;
              }

              const value = option === 'all' ? '' : text;

              return <SelectItem key={`${filterKey}-option-${option}`} value={value} text={text} />;
            })}
          </Select>
        );
      })}

      {!hideClearButton && (
        <Button
          size="small"
          type="reset"
          className="rto-data-table__filter-clear"
          onClick={() => {
            setFilterValues(
              getTab === 'hr'
                ? { from_date: firstWeekDayCurrentYear, to_date: lastWeekDayCurrentYear }
                : {}
            );
          }}
        >
          Clear Filters
        </Button>
      )}

      {getTab === 'hr' && (
        <>
          <Button
            size="small"
            type="reset"
            className="rto-data-table__filter-csv"
            onClick={async () => {
              setIsCsvLoading(true);
              setCsvFileName(`${format(new Date(), 'YYYY-MM-DD-ss')}-Summary-by-employee.csv`);
              setCsvData(await getCsvData());
            }}
            disabled={isCsvLoading}
          >
            Download CSV
          </Button>
          <CSVLink data={csvData} filename={csvFileName} ref={csvEl} />
        </>
      )}
    </Form>
  );
};

RTODataTableFilters.propTypes = {
  selectChange: PropTypes.func.isRequired,
  getCsvData: PropTypes.func,
  filters: PropTypes.objectOf(PropTypes.arrayOf),
  getTab: PropTypes.string
};

RTODataTableFilters.defaultProps = {
  getCsvData: () => {},
  filters: {},
  getTab: 'admin'
};

export default RTODataTableFilters;
