/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import { Button, Form, FormGroup, TextInput } from 'carbon-components-react';
import Alerts from '../Alerts/Alerts';

import UserContext from '../../../context/userContext';

import { updateRequestStatusApi } from '../../../utils/APIs';
import * as helper from '../../../utils/CoreHelpers';
import Copy from '../../../utils/Copy';

function ApprovalForm({ request }) {
  const [alertType, setAlertType] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [hasReject, setHasReject] = useState(false);
  const [hasDecision, setHasDecision] = useState(false);
  const [isDoingAction, setIsDoingAction] = useState(false);
  const [commentForRequestor, setCommentForRequestor] = useState('');
  const [commentForLeadApprover, setCommentForLeadApprover] = useState('');
  const { state: userState } = useContext(UserContext);
  const user = userState.isImpersonatingUser ? userState.asUser : userState.user;
  const thisRequest = request !== null ? request : {};
  const departmentLeadApproverArr = thisRequest.departmentLeadApprover;
  const departmentLeadApproverNameArr = thisRequest.departmentLeadApproverName;
  const isApproverAlsoDepartmentLeadApprover = departmentLeadApproverArr.includes(user.Email);

  // eslint-disable-next-line react/prop-types
  const HelperText = ({ children }) => <p className="request-form__helper-text">{children}</p>;

  function handleRejectBtn1() {
    setHasReject(true);
  }

  function handleRejectBtn2() {
    updateRequestStatusApi(
      user.Email,
      thisRequest.id,
      'Rejected',
      commentForRequestor,
      commentForLeadApprover
    ).then(() => {
      setAlertType('rejected');
      setAlertMsg(Copy.reject_request_success());

      setHasDecision(true);
      setIsDoingAction(false);
    });
  }

  function handleApproveBtn() {
    const requestUpdateStatus = isApproverAlsoDepartmentLeadApprover
      ? 'Approved'
      : 'Awaiting Department Lead Decision';

    updateRequestStatusApi(
      user.Email,
      thisRequest.id,
      requestUpdateStatus,
      commentForRequestor,
      commentForLeadApprover
    ).then(res => {
      if (res.status === 1) {
        setAlertType('approved');

        if (user.Role === 'Vacation Approver') {
          setAlertMsg(Copy.approval_success_by_VA());
        }

        const isAdminHrOrLead = user.Role.search(/Admin|Department|HR/) === 0;

        if (isAdminHrOrLead) {
          setAlertMsg(Copy.approval_success_by_DL());
        }

        setHasDecision(true);
      } else {
        setAlertType('warning');
        setAlertMsg(Copy.approval_fail());
      }

      setIsDoingAction(false);
    });
  }

  function handleCancelBtn() {
    setHasReject(false);
  }

  function handleSetCommentForRequestor(e) {
    setCommentForRequestor(e.target.value);
  }

  function handleSetCommentForLeadApprover(e) {
    setCommentForLeadApprover(e.target.value);
  }

  return (
    <>
      {hasDecision && <Alerts type={alertType} message={alertMsg} />}

      {!hasDecision && (
        <Form className="form approval-form">
          <FormGroup legendText="Comments for Requestor" className="request-form__fieldset">
            <HelperText>
              These will be shared with {thisRequest.requestorFullName} if you reject this request
              {''}
              {!isApproverAlsoDepartmentLeadApprover &&
                thisRequest.requestType !== 'Work From Home' &&
                ` (or upon the department lead's approval decision if you approve this request)`}
              .
            </HelperText>
            <TextInput
              hideLabel
              labelText="Text Input label"
              id="comment-for-requestor"
              onChange={e => handleSetCommentForRequestor(e)}
            />
          </FormGroup>
          {!isApproverAlsoDepartmentLeadApprover && thisRequest.requestType !== 'Work From Home' && (
            <FormGroup
              legendText="Comments for Department Lead approver"
              className="request-form__fieldset"
            >
              <HelperText>
                These will be shared with {helper.formatListOfNames(departmentLeadApproverNameArr)}{' '}
                upon your approval decision.
              </HelperText>
              <TextInput
                hideLabel
                labelText="Text Input label"
                id="comment-for-lead"
                onChange={e => handleSetCommentForLeadApprover(e)}
              />
            </FormGroup>
          )}

          <div className="approval-form__btns">
            {!hasReject ? (
              <>
                <Button
                  onClick={() => handleRejectBtn1()}
                  style={{ backgroundColor: '#cc333f' }}
                  disabled={isDoingAction}
                >
                  Reject
                </Button>{' '}
                <Button
                  onClick={() => {
                    setIsDoingAction(true);
                    handleApproveBtn();
                  }}
                  style={{ backgroundColor: '#60D17C' }}
                  disabled={isDoingAction}
                >
                  {!isDoingAction ? 'Approve' : 'Processing...'}
                </Button>
              </>
            ) : (
              <>
                <p style={{ marginBottom: '1rem' }}>Are you sure?</p>
                <Button
                  onClick={() => {
                    setIsDoingAction(true);
                    handleRejectBtn2();
                  }}
                  style={{ backgroundColor: '#cc333f' }}
                  disabled={isDoingAction}
                >
                  {!isDoingAction ? 'Yes, Reject' : 'Processing...'}
                </Button>{' '}
                <Button
                  onClick={() => handleCancelBtn()}
                  style={{ backgroundColor: '#969696' }}
                  disabled={isDoingAction}
                >
                  No, Cancel
                </Button>
              </>
            )}
          </div>
        </Form>
      )}
    </>
  );
}

export default ApprovalForm;
