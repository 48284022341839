import { vars } from './vars';

const Copy = {
  access_denied: () => 'Access Denied.',
  unauthorized_user: () =>
    `The Responsible Time Off (RTO) system is only available to employees that are set up as users in Grail and have access based on their assigned Division (e.g. C&T New York, Mediacurrent, Kettle, etc.).<br /><br />If you have any questions, please <a href="mailto:${vars.FORM_ADMIN_EMAIL}" target="_blank">email the RTO Help Desk.</a>`,
  user_not_found: () =>
    `In order to log into RTO you must be a Grail user. Please log into RTO with the same email address you use for Grail. If you do not have a Grail account please contact <a href="mailto:grail@codeandtheory.com">grail@codeandtheory.com</a>.`,
  expired_token: () =>
    `(Expired Token) Something went wrong, please refresh and try again. If this issue persists, please contact <a href="mailto:${vars.FORM_ADMIN_EMAIL}" target="_blank">the RTO Help Desk.</a>`,
  invalid_token: () =>
    `(Invalid Token) Something went wrong, please refresh and try again. If this issue persists, please contact <a href="mailto:${vars.FORM_ADMIN_EMAIL}" target="_blank">the RTO Help Desk.</a>`,
  request_not_found: () => 'Request not found.',
  request_submit_success: () =>
    'Your request has been submitted and you will be notified via email of the final approval decision within 1-2 business days.',
  request_submit_success_override_with_notif: () =>
    'This request has been submitted and automatically approved. A confirmation email has been sent to the employee and a notification email has been sent to their vacation approver and relevant team members.',
  request_submit_success_override_without_notif: () =>
    'This request has been submitted and automatically approved. No emails have been sent.',
  request_submit_success_SL: () =>
    'Your vacation approver and team members will be notified via email.',
  request_submit_success_SL_override_without_notif: () =>
    'This Sick/Emergency day has been submitted. No emails have been sent.',
  request_submit_incomplete: () => 'Please address the following items:',
  request_submit_error: () =>
    'Something went wrong. Your request did not go through. Please reload the page and try again.',
  view_request_acknowledged_SL: date => `This Sick/Emergency day was submitted on ${date}.`,
  view_request: (name, status, date) => `${name} already ${status} this on ${date}.`,
  reject_request_success: () =>
    `You have rejected this request and the requestor has been emailed.`,
  approval_success_by_VA: () =>
    'You have approved this request and the department lead has been emailed for their approval.',
  approval_success_by_DL: () =>
    'You have approved this request. The requestor and direct working team have been emailed.',
  approval_as_is_success_by_DL: () =>
    'You have approved this request as-is. The requestor and direct working team have been emailed.',
  approval_as_is_success_by_VA: () =>
    'You have approved this request as-is, and the department Lead has been emailed for their approval.',
  approval_as_is_success_by_VA_WFH: () =>
    'You have approved this request as-is, and the requestor and their relevant team members have been notified via email.',
  approval_fail: () =>
    `Something went wrong while submitting this form. Please refresh the page and try again. If the problem persists, please <a href="mailto:${vars.FORM_ADMIN_EMAIL}">email the form administrator</a>.`,
  form_warning: grailStatus =>
    `<p>You currently have ${grailStatus.join(
      ', '
    )} timesheets and will not be able to submit new Vacation or Work From Home requests.</p>
    <p>Please visit <a href=${
      vars.GRAIL_TIMESHEET_URL
    } rel="noopener noreferrer" target="_blank">Grail</a> and submit any missing or rejected timesheets.</p>
    <p>Until you've completed your timesheets, you will only be able to submit this form for Sick/Emergency, Parental, Jury Duty & Bereavement days.</p>`,
  out_of_order: () =>
    `Someone else's approval is required first before you can access this approval form.<br/><br/>If you feel this is a mistake, please <a href="mailto:${vars.FORM_ADMIN_EMAIL}">email the form administrator</a>.`
};

export default Copy;
