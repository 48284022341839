/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Location, navigate } from '@reach/router';
import { Button, DataTable, Loading } from 'carbon-components-react';
import ChevronRight16 from '@carbon/icons-react/es/chevron--right/16';

import UserContext from '../../../context/userContext';
import LeavesContext from '../../../context/leavesContext';

import RTODataTable from '../RTODataTable/RTODataTable';

import { getAwaitingLeavesApi } from '../../../utils/APIs';
import * as helper from '../../../utils/CoreHelpers';

import './AwaitingDecisionTable.scss';

const { TableHeader, TableRow, TableCell } = DataTable;

function AwaitingDecisionTable() {
  const [leavesAwaiting, setLeavesAwaiting] = useState([]);
  const { state } = useContext(LeavesContext);
  const { state: userState } = useContext(UserContext);
  const user = userState.isImpersonatingUser ? userState.asUser : userState.user;
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    /* eslint-disable-next-line no-use-before-define */
    const sortedLeavesAwaiting = doCustomTableInitialSort(state.leavesAwaitingApproval);

    // Set Global state to local table state.
    setLeavesAwaiting(sortedLeavesAwaiting);
  }, [state.leavesAwaitingApproval]);

  function doCustomTableInitialSort(currentLeavesAwaiting) {
    const leavesAwaitingApproval = currentLeavesAwaiting
      .sort((a, b) => {
        if (a.submitted > b.submitted) return -1;
        if (a.submitted < b.submitted) return 1;
        return 0;
      })
      .sort((c, d) => {
        if (c.requestorFullName > d.requestorFullName) return 1;
        if (c.requestorFullName < d.requestorFullName) return -1;
        return 0;
      });

    return leavesAwaitingApproval;
  }

  function refreshTable() {
    setIsloading(true);

    // Fetch all requests awaiting decision from a user.
    getAwaitingLeavesApi(user.Email).then(res => {
      if (res.data.length > 0) {
        const updatedSortedLeavesAwaiting = doCustomTableInitialSort(res.data);

        setLeavesAwaiting(updatedSortedLeavesAwaiting);
      }

      setIsloading(false);
    });
  }

  const ApprovalActions = ({ requestId }) => {
    return (
      <Location>
        {({ location }) => (
          <>
            <Button
              onClick={() => {
                navigate(`/approval-page/as-is/${requestId}`, {
                  state: {
                    ...location.state,
                    dashboard: location.pathname
                  }
                });
              }}
              size="small"
              renderIcon={ChevronRight16}
              className="rto-button"
              style={{ marginRight: '15px', backgroundColor: '#50B369' }}
            >
              Approve As-Is
            </Button>
            <Button
              onClick={() => {
                navigate(`/approval-page/form/${requestId}`, {
                  state: {
                    ...location.state,
                    dashboard: location.pathname
                  }
                });
              }}
              size="small"
              renderIcon={ChevronRight16}
              className="rto-button"
              style={{ backgroundColor: '#0087B0' }}
            >
              Approval Form
            </Button>
          </>
        )}
      </Location>
    );
  };

  const CellRenders = ({ rowId, cellId, cellValue, rawData }) => {
    switch (cellId) {
      case 'submitted': {
        return (
          <div>
            <p>
              <span>
                {moment
                  .utc(cellValue)
                  .tz(helper.getCurrentLocationTimezone())
                  .format('ddd, MM/DD/YY hh:mm A')}{' '}
                {moment.tz(helper.getCurrentLocationTimezone()).zoneAbbr()}
              </span>
            </p>
            <span>{rawData.requestRefNum}</span>
          </div>
        );
      }
      case 'fromDate':
        return (
          <>
            <div className="table-cell__request-dates">
              {rawData.workDays > 1 ? (
                <>
                  <span>{moment(cellValue).format('ddd, M/D')}</span>
                  <span>&rarr;</span>
                  <span>{moment(rawData.toDate).format('ddd, M/D/YY')}</span>
                </>
              ) : (
                <span>{moment(cellValue).format('ddd, M/D/YY')}</span>
              )}
            </div>

            {rawData.workDays > 1 ? (
              <div>{`(${rawData.workDays} work days)`}</div>
            ) : (
              <div>
                {rawData.halfDayPeriod !== '' && `(${rawData.halfDayPeriod.toLowerCase()} only)`}
              </div>
            )}
          </>
        );
      case 'actions':
        return <ApprovalActions requestId={rowId} />;
      default:
        return cellValue;
    }
  };

  return (
    <div className="rto-awaiting-table">
      {isLoading && <Loading small withOverlay />}
      <RTODataTable
        title="Requests Awaiting Your Approval Decision"
        tableHeaders={[
          { key: 'requestorFullName', header: 'Employee' },
          { key: 'submitted', header: 'Submitted' },
          { key: 'requestType', header: 'Type' },
          { key: 'fromDate', header: 'Date(s)' },
          { key: 'reason', header: 'Reason' },
          { key: 'requestorComments', header: 'Comments' },
          { key: 'actions', header: 'Approval' }
        ]}
        tableRows={leavesAwaiting}
        withRefresh
        refreshAction={refreshTable}
        withSorting
        headersRender={(headers, getHeaderProps) =>
          headers.map(header => {
            if (header.key === 'actions') {
              return <TableHeader key={header.key}>{header.header}</TableHeader>;
            }

            return (
              <TableHeader key={header.key} {...getHeaderProps({ header })}>
                {header.header}
              </TableHeader>
            );
          })
        }
        rowsRender={rows =>
          rows.map(row => {
            const rawData = leavesAwaiting.find(data => data.id === row.id);

            if (!rawData) return null;

            return (
              <TableRow key={row.id}>
                {row.cells.map(cell => {
                  const cellId = cell.id.split(':')[1];

                  return (
                    <TableCell key={cell.id} className="rto-data-table__cell--awaiting">
                      <CellRenders
                        rowId={row.id}
                        cellId={cellId}
                        cellValue={cell.value}
                        rawData={rawData}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        }
      />
    </div>
  );
}

export default AwaitingDecisionTable;
