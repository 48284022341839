/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';

import { DataTable } from 'carbon-components-react';
import PropTypes from 'prop-types';
import Badges from '../Badges/Badges';
import RTODataTable from '../RTODataTable/RTODataTable';
import RTODataTableFilters from '../RTODataTableFilters/RTODataTableFilters';

import LeavesContext from '../../../context/leavesContext';

const { TableRow, TableCell } = DataTable;

function LeavesSummaryTable(props) {
  const { handleYear } = props;
  const [currentLeaves, setCurrentLeaves] = useState([]);
  const { state } = useContext(LeavesContext);
  // Just an extra state to reset/re-render the RTODataTableFilters component
  // every global state change. Updates using timestamp.
  const [keyReset, setKeyReset] = useState(Date.now());

  const leavesSummaryKeysArr = state.leavesSummary ? Object.keys(state.leavesSummary) : [];

  useEffect(() => {
    if (leavesSummaryKeysArr.length !== 0) {
      const processedLeave = processSummaryDataIntoRows(state.leavesSummary);

      setCurrentLeaves(processedLeave);
      setKeyReset(Date.now());
    }
  }, [state.leavesSummary]);

  const filterLeavesSummary = useCallback(
    filterData => {
      if (state.leavesSummary) {
        const selectedFilter = Object.values(filterData);

        if (selectedFilter.length !== 0) {
          const filteredSummary = processSummaryDataIntoRows(
            state.leavesSummary,
            selectedFilter[0]
          );
          setCurrentLeaves(filteredSummary);

          if (handleYear) {
            handleYear(selectedFilter[0]);
          }
        }
      }
    },
    [state.leavesSummary, keyReset]
  );

  function processSummaryDataIntoRows(leavesSummary, year) {
    if (!leavesSummary || leavesSummary.length === 0) return [];

    const periodsArray = [
      { id: 'upcoming', period: 'Upcoming Days' },
      { id: 'past', period: 'Past Days' },
      { id: 'total', period: 'Total' }
    ];
    const rows = periodsArray.map(item => {
      const leaveTypesWithCount = {
        vc: { approved: 0, pending: 0 },
        sc: { approved: 0, pending: 0 },
        pl: { approved: 0, pending: 0 }, // Parental Leave
        bv: { approved: 0, pending: 0 }, // Bereavement
        ju: { approved: 0, pending: 0 }, // Jury Duty
        totalVcScLeaves: { approved: 0, pending: 0 }
      };
      const { vc, sc, pl, bv, ju, totalVcScLeaves } = leaveTypesWithCount;

      Object.entries(leavesSummary).forEach(([key, leaveObj]) => {
        // If summary is filtered by year.
        if (year && year !== key) return;

        const {
          'Sick/Emergency': sickObj,
          Vacation: vacationObj,
          'Parental Leave': parentalObj,
          Bereavement: bereavementObj,
          'Jury Duty': juryObj
        } = leaveObj;

        vc.approved += vacationObj.approved[item.id];
        vc.pending += vacationObj.pending[item.id];
        sc.approved += sickObj.approved[item.id];
        sc.pending += sickObj.pending[item.id];
        totalVcScLeaves.approved += vacationObj.approved[item.id] + sickObj.approved[item.id];
        totalVcScLeaves.pending += vacationObj.pending[item.id] + sickObj.pending[item.id];
        pl.approved += parentalObj.approved[item.id];
        pl.pending += parentalObj.pending[item.id];
        bv.approved += bereavementObj.approved[item.id];
        bv.pending += bereavementObj.pending[item.id];
        ju.approved += juryObj.approved[item.id];
        ju.pending += juryObj.pending[item.id];
      });

      return {
        id: item.id,
        period: item.period,
        vacation: JSON.stringify(vc),
        sick: JSON.stringify(sc),
        parental: JSON.stringify(pl),
        bereavement: JSON.stringify(bv),
        jury: JSON.stringify(ju),
        total_vacation_sick: JSON.stringify(totalVcScLeaves)
      };
    });

    return rows;
  }

  const Description = () => (
    <>
      <span>Days which have not yet been approved are shown in </span>
      <Badges type="warning" text="yellow" />
    </>
  );

  const FilterRender = useMemo(
    () => (
      <RTODataTableFilters
        key={keyReset}
        filters={{
          year:
            state.leavesSummary !== null || state.leavesSummary !== undefined
              ? leavesSummaryKeysArr
              : []
        }}
        selectChange={filterLeavesSummary}
      />
    ),
    [state.leavesSummary, keyReset]
  );

  return (
    <RTODataTable
      title="Summary"
      description={<Description />}
      tableHeaders={[
        { key: 'period', header: 'Period' },
        { key: 'vacation', header: 'Vacation' },
        { key: 'sick', header: 'Sick/Emergency' },
        { key: 'total_vacation_sick', header: 'Total Vacation + Sick/Emergency' },
        { key: 'parental', header: 'Parental Leave' },
        { key: 'bereavement', header: 'Bereavement' },
        { key: 'jury', header: 'Jury Duty' }
      ]}
      ToolbarExtraComponents={FilterRender}
      tableRows={currentLeaves}
      rowsRender={rows =>
        rows.map(row => (
          <TableRow key={row.id}>
            {row.cells.map(cell => {
              if (cell.id === `${row.id}:period`) {
                return <TableCell key={cell.id}>{cell.value}</TableCell>;
              }

              const { approved, upcoming, pending } = JSON.parse(cell.value);

              return (
                <TableCell key={cell.id}>
                  <span
                    style={{
                      color: approved === 0 ? '#999999' : ''
                    }}
                  >
                    {approved}
                  </span>
                  {upcoming && upcoming !== 0 ? (
                    <span style={{ color: '#888888' }}>{` (+${upcoming}) `}</span>
                  ) : (
                    ' '
                  )}
                  {pending !== 0 && <Badges type="warning" text={`+${pending}`} />}
                </TableCell>
              );
            })}
          </TableRow>
        ))
      }
    />
  );
}

LeavesSummaryTable.propTypes = {
  handleYear: PropTypes.func
};

LeavesSummaryTable.defaultProps = {
  handleYear: () => {
    return '';
  }
};

export default LeavesSummaryTable;
