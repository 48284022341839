/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { getDay, format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function RTODatePicker(props) {
  const { id, value, minDate, maxDate, disabled, placeholderText, excludedDates, onChange } = props;

  const handleChange = e => onChange(e);

  const handleDisableDates = e => {
    const day = getDay(e);
    const isExcluded = excludedDates.find(d => {
      const a = format(d, 'M-D-YYYY');
      const b = format(e, 'M-D-YYYY');
      return a === b;
    });

    return day !== 0 && day !== 6 && !isExcluded;
  };

  return (
    <div>
      <DatePicker
        id={id}
        selected={value}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        placeholderText={placeholderText}
        showDisabledMonthNavigation
        dateFormat="M/d/yyyy"
        filterDate={e => handleDisableDates(e)}
        onChange={e => handleChange(e)}
      />
    </div>
  );
}

RTODatePicker.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  placeholderText: PropTypes.string,
  excludedDates: PropTypes.array,
  onChange: PropTypes.func
};

RTODatePicker.defaultProps = {
  id: '',
  disabled: false,
  excludedDates: [],
  placeholderText: 'Click to select a date',
  onChange: () => {}
};

export default RTODatePicker;
