import { createContext } from 'react';

export const initialState = {
  requestorEmail: '',
  requestorJson: '',
  datesJson: '',
  workDays: 0,
  requestType: 'Vacation',
  fromDate: '',
  toDate: '',
  halfDayPeriod: '',
  reason: '',
  relevantTeamMembersEmails: '',
  requestorComments: ''
};

const requestContext = createContext(initialState);

export default requestContext;
