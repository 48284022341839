import React from 'react';
import PropTypes from 'prop-types';

import './Badges.scss';

function textStatusChecker(text) {
  let badgeType;

  switch (text.toLowerCase()) {
    case 'awaiting vacation approver decision':
    case 'awaiting department lead decision':
    case 'pending approval': {
      badgeType = 'warning';
      break;
    }
    case 'acknowledged':
    case 'approved': {
      badgeType = 'success';
      break;
    }
    case 'rejected': {
      badgeType = 'danger';
      break;
    }
    case 'cancelled': {
      badgeType = 'info';
      break;
    }
    default: {
      badgeType = 'warning';
      break;
    }
  }

  return badgeType;
}

function Badges(props) {
  const { type, text } = props;
  const badgeClass = type || textStatusChecker(text);

  return <span className={`badge badge--${badgeClass}`}>{text}</span>;
}

Badges.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string
};

Badges.defaultProps = {
  type: '',
  text: ''
};

export default Badges;
