/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-console */
import React, { useState, useContext, useEffect, useReducer } from 'react';
import { navigate } from '@reach/router';
import { InlineLoading } from 'carbon-components-react';
import PropTypes from 'prop-types';

import UserContext from '../../../context/userContext';
import LeavesContext, { initialState } from '../../../context/leavesContext';
import LeavesReducer from '../../../context/leavesReducer';

import Alerts from '../../modules/Alerts/Alerts';
import LeavesSummaryTable from '../../modules/LeavesSummaryTable/LeavesSummaryTable';
import RequestDetailsTable from '../../modules/RequestDetailsTable/RequestDetailsTable';

import { getLeavesRequestApi, getLeavesSummaryApi } from '../../../utils/APIs';

function ExistingRequests(props) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [state, dispatch] = useReducer(LeavesReducer, initialState);
  const { state: userState } = useContext(UserContext);
  const { location } = props;

  // Set user email to get data
  // Do not wait for context to get updated
  let userEmail =
    location.state && location.state.impersonateUser
      ? location.state.impersonateUser
      : userState.user.Email;

  useEffect(() => {
    // Redirect to '/existing-requests' on first click to impersonate
    // Not running on succeeding page refresh
    if (Object.prototype.hasOwnProperty.call(props, 'impersonateUser')) {
      navigate('/existing-requests', { state: { impersonateUser: props.impersonateUser } });
      userEmail = location.pathname.split('/')[location.pathname.split('/').length - 1];
    }
  }, []);

  useEffect(() => {
    try {
      Promise.all([getLeavesSummaryApi(userEmail), getLeavesRequestApi(userEmail)]).then(res => {
        if (res[0] && res[1]) {
          const summary = res[0];
          const request = res[1];

          if (summary.status !== 0) {
            // Fetch all requests by a user.
            dispatch({ type: 'GET_LEAVES_SUMMARY', payload: summary.data });
          }

          if (request.status !== 0) {
            // Fetch leaves summary of the user.
            dispatch({ type: 'GET_LEAVES_REQUESTS', payload: request.data });
          }
        }
      });
    } catch (err) {
      console.log(err);
    }

    setHasLoaded(true);
  }, [userState.isImpersonatingUser]);

  if (!hasLoaded) {
    return <InlineLoading style={{ margin: '1rem 0' }} description="Loading..." />;
  }

  return (
    <div className="existing-requests-page">
      {location.state && location.state.showAlert && (
        <Alerts type={location.state.alertType} message={location.state.alertMsg} />
      )}

      {!state.leaveRequests || state.leavesRequests.length !== 0 ? (
        <LeavesContext.Provider value={{ state, dispatch }}>
          <LeavesSummaryTable />
          <RequestDetailsTable />
        </LeavesContext.Provider>
      ) : (
        <p>You haven&apos;t made any requests yet. Use the form above to submit a new request.</p>
      )}
    </div>
  );
}

ExistingRequests.propTypes = {
  location: PropTypes.object
};

ExistingRequests.defaultProps = {
  location: {}
};

export default ExistingRequests;
