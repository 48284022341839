/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  state = {
    // hasError: false,
    error: null,
    errorInfo: null
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });
    // You can also log error messages to an error reporting service here
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { error, errorInfo } = this.state;

    if (errorInfo) {
      return (
        <div style={{ minHeight: 'auto', lineHeight: '1.5' }}>
          <h3>Ooops! Something went wrong.</h3>
          <p>
            Please email our dev team at{' '}
            <a href="mailto:rto.team@codeandtheory.com">rto.team@codeandtheory.com</a> to fix the
            error.
          </p>
          <div style={{ margin: '20px 0' }}>
            <button type="button" onClick={() => window.location.reload()}>
              Refresh page
            </button>{' '}
            <button type="button" onClick={() => window.history.back()}>
              Go back
            </button>
          </div>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && <p>The error: {error.toString()}</p>}
            <p>Where it occured: {errorInfo.componentStack}</p>
          </details>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
