import { createContext } from 'react';

export const initialState = {
  isLogged: localStorage.getItem('isLogged') || false,
  user: JSON.parse(localStorage.getItem('activeUser')) || {},
  isImpersonatingUser: false,
  asUser: {},
  hasLoginError: false,
  loginError: '',
  grailStatus: []
};

const userContext = createContext(initialState);

export default userContext;
