/* eslint-disable react/prop-types */
import React from 'react';
import { startOfWeek, addDays, format } from 'date-fns';

import { vars } from '../../../utils/vars';

import './Calendar.scss';

function Calendar({ user, request }) {
  const userDiscipline = user.Discipline;
  const mondayOfWeek = startOfWeek(request.fromDate, { weekStartsOn: 1 });
  const sundayOfWeek = addDays(mondayOfWeek, 6);
  const datesString = `${format(mondayOfWeek, 'YYYYMMDD')}/${format(sundayOfWeek, 'YYYYMMDD')}`;
  const calendarSrc = vars.CALENDAR_ID[userDiscipline];

  if (!calendarSrc) return false;

  return (
    <section className="calendar">
      <h1 className="approval-page__header">OOO Calendar ({userDiscipline})</h1>
      <hr />
      <div style={{ padding: '1em 0', overflow: 'auto' }}>
        <a
          href={`https://www.google.com/calendar/render?cid=${calendarSrc}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ float: 'right' }}
        >
          Add this calendar to your Google Calendar sidebar »
        </a>
      </div>
      <div className="calendar__iframe">
        <iframe
          src={`https://calendar.google.com/calendar/embed?showTitle=0&showPrint=0&showCalendars=0&showTz=0&mode=WEEK&height=600&wkst=2&bgcolor=%23FFFFFF&src=${calendarSrc}&color=%23b7b7b7&ctz=America%2FNew_York&dates=${datesString}`}
          style={{ border: 0 }}
          width="800"
          height="600"
          frameBorder="0"
          scrolling="no"
          title="Calendar"
        />
      </div>
    </section>
  );
}

export default Calendar;
