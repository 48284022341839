/* eslint-disable no-use-before-define */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
/*
  Request Type:
  - Check if "requestor" still have valid leaves available
  - Then toggle selectability of radio buttons
  - Default: Vacation

  Approval Flow:
  - Show based on chosen Request Type
  - Show requestor's vacation approver name.

  From/To:
  - If Vacation Type:
    -

  - If Work From Home Type
    -

  - If Sick Leave
    - Hide the From/To fields
    - Show Date field and Period dropdown

  Period:
  - Show if request is 1day only
  - Default option: Full Day

  Request Info
  - Display only after choosing both From/To dates

  General Reason
  - Required

  Lead Producer(s)
  - Required. Autocomplete

  Project-Related Comments
  - Optional
*/

import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Form,
  FormGroup,
  RadioButtonGroup,
  RadioButton,
  OrderedList,
  UnorderedList,
  ListItem,
  Dropdown,
  TextInput,
  TextArea,
  InlineLoading
} from 'carbon-components-react';
import AsyncSelect from 'react-select/async';
import {
  format,
  distanceInWordsStrict,
  isToday,
  addDays,
  addMonths,
  isFriday,
  isSaturday,
  isPast,
  isFuture,
  isTomorrow,
  addMinutes,
  subMinutes
} from 'date-fns';
import { navigate } from '@reach/router';
import ReactGA from 'react-ga';

import RTODatePicker from '../../generic/DatePicker/DatePicker';
import Alerts from '../Alerts/Alerts';
import UserContext from '../../../context/userContext';
import RequestContext from '../../../context/requestContext';

import { getGrailStatusApi, postNewRequestApi, getExcludedDatesApi } from '../../../utils/APIs';

import './RequestForm.scss';

import Copy from '../../../utils/Copy';

const requestTypes = {
  vacation: 'Vacation',
  remote: 'Work From Home',
  sick: 'Sick/Emergency',
  parentalLeave: 'Parental Leave',
  juryDuty: 'Jury Duty',
  bereavement: 'Bereavement'
};

const reqNotRequiringApproval = ['Sick/Emergency', 'Parental Leave', 'Jury Duty', 'Bereavement'];

const workPeriods = [
  { id: 'FullDay', text: 'Full Day' },
  { id: 'Morning', text: 'Morning Only' },
  { id: 'Afternoon', text: 'Afternoon Only' }
];

function RequestForm(props) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isNotAllowed, setIsNotAllowed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState({ state: false, type: 'default' });
  const [hasSubmitted, setHasSubmitted] = useState({ state: false, type: '' });
  const [isFormIncomplete, setIsFormIncomplete] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [excludedDates, setExcludedDates] = useState({ raw: [], formatted: [] });
  const { state: userState } = useContext(UserContext);
  const { state: requestState, dispatch: requestDispatch } = useContext(RequestContext);
  const sessionUsers = sessionStorage.getItem('users');
  const usersList = sessionUsers && sessionUsers !== 'undefined' ? JSON.parse(sessionUsers) : [];
  const user = userState.isImpersonatingUser ? userState.asUser : userState.user;
  const { grailStatus } = userState;
  const isAdminHR = userState.user.Role === 'Admin' || userState.user.Role === 'HR';
  const isReqApprovalNotRequired = reqNotRequiringApproval.includes(requestState.requestType);
  const { locationState } = props;

  const isUserNotAllowedAccess = (status = grailStatus) => {
    return (
      !isAdminHR &&
      (status.includes('empty') || status.includes('unsubmitted') || status.includes('rejected'))
    );
  };

  function getStatus() {
    return getGrailStatusApi(user.Email).then(status => status);
  }

  function formatTimezone(date) {
    const offset = date.getTimezoneOffset();
    return Math.sign(offset) !== -1 ? addMinutes(date, offset) : subMinutes(date, Math.abs(offset));
  }

  function handleSetWorkDays() {
    // Pass the excluded dates array to compute work days and date range correctly.
    const excludedDateStrings = excludedDates.raw.map(item => item.date);

    requestDispatch({ type: 'SET_DATES_JSON', payload: excludedDateStrings });
    requestDispatch({ type: 'SET_WORK_DAYS' });
  }

  function handleSetRequestType(e) {
    // Set Request Type
    requestDispatch({ type: 'SET_REQUEST_TYPES', payload: requestTypes[e] });

    // Reset state values
    requestDispatch({ type: 'SET_FROM_DATE', payload: '' });
    requestDispatch({ type: 'SET_TO_DATE', payload: '' });
    requestDispatch({ type: 'SET_HALFDAY_PERIOD', payload: '' });
    requestDispatch({ type: 'SET_DATES_JSON', payload: '[]' });
    requestDispatch({ type: 'SET_WORK_DAYS', payload: '' });
  }

  function handleSetFromDate(e) {
    const date = e;
    requestDispatch({ type: 'SET_FROM_DATE', payload: date });

    // If changing 'fromDate' value, then reset 'toDate' value
    requestDispatch({ type: 'SET_TO_DATE', payload: '' });

    if (requestState.requestType !== requestTypes.sick) {
      requestDispatch({ type: 'SET_HALFDAY_PERIOD', payload: '' });
    }

    // Calculate WorkDays
    handleSetWorkDays();
  }

  function handleSetToDate(e) {
    const date = e;
    requestDispatch({ type: 'SET_TO_DATE', payload: date });

    if (requestState.requestType !== requestTypes.sick) {
      requestDispatch({ type: 'SET_HALFDAY_PERIOD', payload: '' });
    }

    handleSetWorkDays();
  }

  function handleSetPeriod(e) {
    requestDispatch({ type: 'SET_HALFDAY_PERIOD', payload: e === 'FullDay' ? '' : e });
    handleSetWorkDays();
  }

  function handleSetReason(e) {
    requestDispatch({ type: 'SET_REASON', payload: e.target.value });
  }

  function handleSetTeamMembers(e, action) {
    const selected = [...selectedOptions];

    if (action.action === 'select-option') {
      if (e !== null && e.length !== 0) {
        const newEntry = e !== null ? e[e.length - 1] : {};
        if (newEntry.value !== undefined) {
          selected.push(newEntry);
          setSelectedOptions(selected);
          requestDispatch({ type: 'SET_TEAM_MEMBERS', payload: selected });
        }
      }
    }

    if (action.action === 'remove-value') {
      const newSelected = selected.filter(sel => sel.value !== action.removedValue.value);
      setSelectedOptions(newSelected);
      requestDispatch({ type: 'SET_TEAM_MEMBERS', payload: newSelected });
    }
  }

  function handleSetRequestorComment(e) {
    requestDispatch({ type: 'SET_REQUESTOR_COMMENTS', payload: e.target.value });
  }

  function isFormValid() {
    return (
      requestState.fromDate !== '' &&
      requestState.toDate !== '' &&
      requestState.reason !== '' &&
      requestState.relevantTeamMembersEmails !== ''
    );
  }

  /* payload checker. Currently set to check datesJson only. Other requestState properties require
  additional review before adding it here to prevent over-checking within the RequestForm module */
  function isPayloadComplete() {
    if (!requestState.datesJson) {
      handleSetWorkDays();
    }
  }

  function handleSubmitButton(submitType = 'default') {
    setIsSubmitting({ state: true, type: submitType });

    function postRequest() {
      if (isFormValid()) {
        isPayloadComplete();
        postNewRequestApi(requestState, submitType).then(res => {
          if (res.status === 1) {
            let alertCopyText =
              requestState.requestType === 'Sick/Emergency'
                ? Copy.request_submit_success_SL()
                : Copy.request_submit_success();

            if (submitType === 'override_WithNotif') {
              alertCopyText = Copy.request_submit_success_override_with_notif();
            }

            if (submitType === 'override_NoNotif') {
              alertCopyText =
                requestState.requestType === 'Sick/Emergency'
                  ? Copy.request_submit_success_SL_override_without_notif()
                  : Copy.request_submit_success_override_without_notif();
            }

            ReactGA.event({
              category: 'RTO',
              action: 'submit',
              label: requestState.requestType
            });

            navigate(`/existing-requests`, {
              state: {
                ...locationState,
                showAlert: true,
                alertType: 'success',
                alertMsg: alertCopyText
              }
            });
          }
          if (res.status === 0) {
            setIsNotAllowed(true);
            setIsFormIncomplete(true);
          }
          setIsFormIncomplete(false);
        });
      } else {
        setIsSubmitting({ state: false });
        setIsFormIncomplete(true);
      }
    }

    getStatus().then(status => {
      // RR-164
      // Check Grail Status upon form submission
      // If allowed, proceed
      if (!isUserNotAllowedAccess(status['Grail Status'])) {
        postRequest();
        return;
      }

      // If not allowed,
      // Check if request is SL, then proceed
      // Else, stop.
      if (isUserNotAllowedAccess(status['Grail Status'])) {
        if (isReqApprovalNotRequired) {
          postRequest();
        } else {
          setHasSubmitted({ state: true, type: '' });
          setIsNotAllowed(true);
        }
      }
    });
  }

  const radioProps = () => ({
    group: {
      defaultSelected: isUserNotAllowedAccess() ? 'sick' : 'vacation',
      onChange: e => handleSetRequestType(e),
      className: 'request-form__request-types-group'
    },
    btn: {
      className: 'request-form__request-types',
      name: 'test',
      disabled: false
    },
    btn_sl: {
      className: `request-form__request-types bx--tooltip__trigger ${
        !isSLAvailable() ? 'request-form__request-types--disabled' : ''
      }`,
      name: 'test',
      disabled: false
    }
  });

  const textInputProps = () => ({
    className: 'some-class',
    labelText: 'Text Input label',
    hideLabel: true,
    invalidText: 'A valid value is required'
  });

  const textAreaProps = () => ({
    className: 'some-class',
    hideLabel: true,
    labelText: 'Text Area label',
    rows: 4
  });

  // eslint-disable-next-line react/prop-types
  const HelperText = ({ children }) => <p className="request-form__helper-text">{children}</p>;

  const isRequestToday = isToday(requestState.fromDate);
  const isRequestTomorrow = isTomorrow(requestState.fromDate);
  const hasRequestDates =
    requestState.fromDate &&
    requestState.fromDate !== '' &&
    requestState.toDate &&
    requestState.toDate !== '';
  const isRequestSL = requestState.requestType === 'Sick/Emergency';
  const isRequestVL = requestState.requestType === 'Vacation';
  const isRequestHalf = requestState.halfDayPeriod.search(/morning|afternoon/i) !== -1;
  const isSingleDay = requestState.workDays <= 1;
  const workDays = isSingleDay ? 'one' : requestState.workDays;
  const today = new Date();

  function getMaxDate() {
    if (isFriday(today)) return today;
    return addDays(today, 1);
  }

  function limitToDate() {
    if (requestState.fromDate === '') return null;

    const formattedFromDate = format(requestState.fromDate, 'YYYY-MM-DD');
    const filteredExcludedDates = excludedDates.raw
      .sort((firstItem, secondItem) => {
        if (firstItem.date > secondItem.date) return 1;
        if (firstItem.date < secondItem.date) return -1;
        return 0;
      })
      .filter(item => formattedFromDate < item.date && !item.isHoliday);

    if (filteredExcludedDates.length > 0) {
      const dateDistance = distanceInWordsStrict(formattedFromDate, filteredExcludedDates[0].date, {
        unit: 'd'
      });

      const daysValue = dateDistance.split(' ')[0];

      return addDays(requestState.fromDate, daysValue);
    }

    return addMonths(requestState.fromDate, 12);
  }

  /**
   * Function to check if a user can submit a sick day request. A user can submit a sick day if:
   *
   * - they are an admin or HR
   * - today is not Saturday
   * - both today and tomorrow are not days the user already has vacation on, or are not company holidays
   *
   * Note: we check if today is Saturday because sick days can be taken today or tomorrow.
   * Saturday and Sunday are both weekend days so no one should need to take a sick day on the weekend.
   * Sunday _is_ allowed because they could be submitting for Monday.
   *
   * This function should only be used to disable the "Sick Day" radio button.
   *
   * @returns {boolean} true if the sick day option should be available today, otherwise false.
   */
  function isSLAvailable() {
    if (isAdminHR) {
      return true;
    }

    if (isSaturday(today)) {
      return false;
    }

    const excludedDateStrings = excludedDates.raw.map(item => item.date);

    return (
      excludedDateStrings.indexOf(format(today, 'YYYY-MM-D')) === -1 ||
      excludedDateStrings.indexOf(format(getMaxDate(), 'YYYY-MM-D')) === -1
    );
  }

  function getRelevantTeam() {
    const isUserProducer =
      user.Department === 'Production' || user.Title.search(/producer/i) !== -1;
    const userDiscipline = isUserProducer ? 'Production' : user.Discipline;
    const userTeamUpper = 'Key Teammate(s)';

    return {
      userDiscipline,
      userTeamUpper
    };
  }

  function getRelevantTime() {
    const isRequestFromPastDates =
      isPast(requestState.fromDate) && isPast(requestState.toDate) && !isToday(requestState.toDate);
    const isRequestStartsFromPast =
      isPast(requestState.fromDate) &&
      !isToday(requestState.fromDate) &&
      (isFuture(requestState.toDate) || isToday(requestState.toDate));
    const halfDay = requestState.halfDayPeriod.toLowerCase();

    if (!isRequestSL) {
      if (isRequestFromPastDates) {
        if (isSingleDay) return ` (for a past date):`;
        return ` (for past dates):`;
      }

      if (isRequestStartsFromPast) {
        return ` (starting in the past):`;
      }

      if (isRequestToday) {
        if (isRequestHalf) return ` (for this ${halfDay}):`;
        return ` (${!isSingleDay ? 'starting' : 'for'} today):`;
      }
    } else {
      if (isRequestToday) {
        return isRequestHalf ? ` this ${halfDay}.` : ' today.';
      }

      if (isRequestTomorrow) {
        return ` tomorrow${isRequestHalf ? ` ${halfDay}` : ''}.`;
      }
    }

    return isRequestSL ? '.' : ':';
  }

  const filterOptions = input => {
    const filters = [];

    if (input.length >= 3) {
      const match = usersList.filter(u => u.name.toLowerCase().includes(input.toLowerCase()));
      match.forEach(m =>
        filters.push({
          label: `${m.name} (${m.division})`,
          value: m.email
        })
      );
    }

    return filters;
  };

  const getAsyncOptions = async input => {
    let result = [];
    if (input.length > 0) result = await filterOptions(input);
    return result;
  };

  useEffect(() => {
    // Get all excluded dates for calendar
    getExcludedDatesApi(user.Email).then(res => {
      if (res.data !== undefined) {
        const dates = res.data.map(dateObj => formatTimezone(new Date(dateObj.date)));
        setExcludedDates({ raw: res.data, formatted: dates });
      }
    });

    // If allowed to submit request
    // Set default values for these...
    requestDispatch({ type: 'SET_REQUESTOR_JSON', payload: user });
    requestDispatch({ type: 'SET_REQUESTOR_EMAIL', payload: user.Email });

    if (isUserNotAllowedAccess()) {
      requestDispatch({ type: 'SET_REQUEST_TYPES', payload: requestTypes.sick });
    }

    setHasLoaded(true);

    return () => setHasLoaded(false);
  }, [userState.isImpersonatingUser, userState.asUser, hasSubmitted]);

  if (!hasLoaded) {
    return <InlineLoading style={{ margin: '1rem 0' }} description="Loading..." />;
  }

  return (
    <Form className="request-form">
      <FormGroup legendText="Request Type" className="request-form__fieldset">
        <RadioButtonGroup name="radio-button-group" {...radioProps().group}>
          <RadioButton
            value="vacation"
            id="radio-1"
            labelText="Vacation"
            {...radioProps().btn}
            disabled={isUserNotAllowedAccess()}
          />
          <RadioButton
            value="sick"
            labelText="Sick/Emergency"
            id="radio-3"
            {...radioProps().btn_sl}
            disabled={!isSLAvailable()}
          />
          <RadioButton
            value="parentalLeave"
            labelText="Parental Leave"
            id="radio-4"
            {...radioProps().btn_sl}
          />
          <RadioButton
            value="juryDuty"
            labelText="Jury Duty"
            id="radio-5"
            {...radioProps().btn_sl}
          />
          <RadioButton
            value="bereavement"
            labelText="Bereavement"
            id="radio-6"
            {...radioProps().btn_sl}
          />
        </RadioButtonGroup>

        <div className="request-form__explainer">
          {!isUserNotAllowedAccess() && (
            <>
              <p>{`${requestState.requestType} Approval Flow:`}</p>
              <OrderedList className="request-form__explainer__list">
                {!isReqApprovalNotRequired && (
                  <ListItem>
                    This request will be reviewed by your vacation approver (
                    {user['Vacation Approver Full Name']}) and{' '}
                    {getRelevantTeam().userTeamUpper.toLocaleLowerCase()}.
                  </ListItem>
                )}
                {isRequestVL && (
                  <ListItem>
                    If approved by your vacation approver, this request will be reviewed by the{' '}
                    {user.Discipline} department lead.
                  </ListItem>
                )}
                {!isReqApprovalNotRequired ? (
                  <ListItem>
                    You will be notified via email of the final approval decision within 1-2
                    business days.
                  </ListItem>
                ) : (
                  <ListItem>
                    Your vacation approver ({user['Vacation Approver Full Name']}) and{' '}
                    {getRelevantTeam().userTeamUpper.toLocaleLowerCase()} will be notified via
                    email.
                  </ListItem>
                )}
              </OrderedList>
            </>
          )}
          {isUserNotAllowedAccess() && (
            <div dangerouslySetInnerHTML={{ __html: Copy.form_warning(grailStatus) }} />
          )}
        </div>
      </FormGroup>

      <FormGroup legendText="" className="request-form__fieldset">
        <div className="date-pickers">
          <div>
            <label htmlFor="dropdown-fromDate" className="bx--label required-field">
              {isRequestSL ? 'Date' : 'From'}
            </label>
            <RTODatePicker
              id="dropdown-fromDate"
              value={requestState.fromDate}
              excludedDates={excludedDates.formatted}
              minDate={!isAdminHR && isRequestSL && new Date()}
              maxDate={!isAdminHR && isRequestSL && getMaxDate()}
              onChange={e => handleSetFromDate(e)}
            />
          </div>

          {!isRequestSL && (
            <div>
              <label htmlFor="dropdown-toDate" className="bx--label required-field">
                To
              </label>
              <RTODatePicker
                id="dropdown-toDate"
                value={requestState.toDate}
                excludedDates={excludedDates.formatted}
                minDate={requestState.fromDate}
                maxDate={limitToDate()}
                disabled={requestState.fromDate === ''}
                onChange={e => handleSetToDate(e)}
              />
            </div>
          )}

          {((requestState.workDays > 0 && requestState.workDays < 2) || isRequestSL) && (
            <Dropdown
              id="period-selector"
              label="Full Day"
              ariaLabel="Dropdown"
              titleText="Period"
              items={workPeriods}
              itemToString={item => (item ? item.text : '')}
              disabled={requestState.fromDate === ''}
              onChange={e => handleSetPeriod(e.selectedItem.id)}
            />
          )}
        </div>

        {hasRequestDates && (
          <div className="request-form__explainer">
            {!isRequestSL && (
              <p>
                {`You are requesting ${
                  isRequestHalf ? 'a half' : workDays
                } ${requestState.requestType.toLowerCase()} day${
                  !isSingleDay ? 's' : ''
                }${getRelevantTime()}`}
              </p>
            )}

            {isRequestSL && (
              <p>
                {`You ${isRequestToday ? 'are' : 'will be'} taking a ${
                  isRequestHalf ? 'half' : ''
                } sick/emergency day${getRelevantTime()}
                `}
              </p>
            )}

            {!isRequestSL && (
              <UnorderedList
                className={`request-form__explainer__list request-form__explainer__list--dates ${
                  workDays >= 6 ? 'request-form__explainer__list--two-cols' : ''
                }`}
              >
                {JSON.parse(requestState.datesJson).map((el, i) => {
                  const index = i + 1;
                  const listItem = (
                    <ListItem key={el}>
                      {format(new Date(el), 'ddd')}, {format(new Date(el), 'M/D/YY')}{' '}
                      {isRequestHalf ? `(${requestState.halfDayPeriod.toLowerCase()} only)` : ''}
                    </ListItem>
                  );

                  if (workDays > 10) {
                    return index < 10 && listItem;
                  }

                  return listItem;
                })}
                {workDays > 10 && (
                  <ListItem>
                    <span style={{ fontStyle: 'italic' }}>{`(+${workDays - 9} more)`}</span>
                  </ListItem>
                )}
              </UnorderedList>
            )}
          </div>
        )}
      </FormGroup>

      <FormGroup legendText="General Reason" className="request-form__fieldset required-field">
        <HelperText>
          This should be a brief summary, and it&apos;s not necessary to include specific details.
        </HelperText>
        <TextInput
          {...textInputProps()}
          id="request-reason"
          required
          value={requestState.reason}
          onChange={e => handleSetReason(e)}
        />
      </FormGroup>

      <FormGroup
        legendText={getRelevantTeam().userTeamUpper}
        className="request-form__fieldset required-field"
      >
        <HelperText>
          <>
            Select other teammates on your project(s) that must be informed about your request.
            Other teammates can be a Support Producer, TPM, DRI, Developer, and/or QA. Please speak
            to your manager if you have any questions about who you need to inform.
          </>
        </HelperText>
        <HelperText>
          Please Note: This request will go to {user['Vacation Approver Full Name']} by default, no
          need to include them here.
        </HelperText>
        <AsyncSelect
          isMulti
          searchable
          isClearable={false}
          isLoading
          name="requestor-leads"
          loadOptions={getAsyncOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          value={selectedOptions}
          placeholder="Type at least 3 characters."
          loadingMessage={() => 'Loading...'}
          onChange={(e, action) => handleSetTeamMembers(e, action)}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
          }}
          aria-label="Related Team Member"
        />
      </FormGroup>

      <FormGroup legendText="Project-Related Comments" className="request-form__fieldset">
        <HelperText>
          Please provide any relevant details which will be shared with your vacation approver and
          team members, including whether you will have any availability during work hours.
        </HelperText>
        <TextArea
          {...textAreaProps()}
          onChange={e => handleSetRequestorComment(e)}
          value={requestState.requestorComments}
          aria-label="Project-Related Comments"
        />
      </FormGroup>

      <div className="request-form__btns">
        {isFormIncomplete && !isFormValid() && (
          <Alerts type="danger" noIcon message={Copy.request_submit_incomplete()}>
            <UnorderedList className="request-form__explainer__list">
              {requestState.fromDate === '' && <ListItem>Select the start date</ListItem>}
              {requestState.toDate === '' && <ListItem>Select the end date</ListItem>}
              {requestState.reason === '' && <ListItem>Enter the reason for this request</ListItem>}
              {requestState.relevantTeamMembersEmails === '' && (
                <ListItem>
                  Specify your {getRelevantTeam().userTeamUpper.toLocaleLowerCase()}
                </ListItem>
              )}
            </UnorderedList>
          </Alerts>
        )}
        {isFormIncomplete && isNotAllowed && (
          <Alerts type="danger" noIcon message={Copy.request_submit_error()} />
        )}

        {hasSubmitted.state && isNotAllowed && (
          <Alerts type="rejected" message={Copy.form_warning(grailStatus)} />
        )}

        <Button
          onClick={() => handleSubmitButton()}
          style={{
            backgroundColor: '#00a0b0',
            width: '8rem',
            paddingRight: '12px',
            justifyContent: 'center'
          }}
          disabled={isSubmitting.state}
        >
          <span>Submit</span>
          {isSubmitting.state && isSubmitting.type === 'default' && <InlineLoading />}
        </Button>

        {userState.isImpersonatingUser && (
          <>
            {!isRequestSL && (
              <Button
                onClick={() => handleSubmitButton('override_WithNotif')}
                style={{ backgroundColor: '#999' }}
                disabled={isSubmitting.state}
              >
                Override Approval Flow (Send Notifications)
                {isSubmitting.state && isSubmitting.type === 'override_WithNotif' && (
                  <InlineLoading />
                )}
              </Button>
            )}

            <Button
              onClick={() => handleSubmitButton('override_NoNotif')}
              style={{ backgroundColor: '#99b898' }}
              disabled={isSubmitting.state}
            >
              {isRequestSL
                ? 'Submit (Suppress Notifications)'
                : 'Override Approval Flow (Suppress Notifications)'}
              {isSubmitting.state && isSubmitting.type === 'override_NoNotif' && <InlineLoading />}
            </Button>
          </>
        )}
      </div>
    </Form>
  );
}

export default RequestForm;
