/* eslint-disable no-console */
// API
// getLeaveRequests
// getLeaveRequestsAwaiting
// getLeavesAvailable
// getLeavesRTOAllotment
// post

import {
  GET_LEAVES_SUMMARY,
  GET_LEAVES_REQUESTS,
  GET_LEAVES_AVAILABLE,
  GET_LEAVES_AWAITING,
  GET_SUBORDINATES,
  LEAVE_APPROVE_AS_IS,
  GET_REQUEST_INFO,
  CANCEL_REQUEST
} from './types';

const getLeavesSummary = (data, state) => {
  return {
    ...state,
    leavesSummary: data
  };
};

const getLeavesRequest = (data, state) => {
  return {
    ...state,
    leavesRequests: data
  };
};

const getLeavesAvailable = (data, state) => {
  // @TODO: Fetch API Data here
  console.log(data);

  return {
    ...state
  };
};

const getLeavesAwaiting = (data, state) => {
  return {
    ...state,
    leavesAwaitingApproval: data
  };
};

const getSubordinates = (data, state) => {
  return {
    ...state,
    subordinates: data
  };
};

const setLeavesApproveAsIs = (data, state) => {
  // @TODO: Fetch API Data here
  console.log('approve as is');

  return {
    ...state
  };
};

const getRequestInfo = (data, state) => {
  return {
    ...state,
    requestInfo: data
  };
};

const setCancelRequest = (data, state) => {
  return {
    ...state,
    leavesRequests: data
  };
};

export default (state, action) => {
  switch (action.type) {
    case GET_LEAVES_SUMMARY:
      return getLeavesSummary(action.payload, state);
    case GET_LEAVES_REQUESTS:
      return getLeavesRequest(action.payload, state);
    case GET_LEAVES_AVAILABLE:
      return getLeavesAvailable(action.payload, state);
    case GET_LEAVES_AWAITING:
      return getLeavesAwaiting(action.payload, state);
    case GET_SUBORDINATES:
      return getSubordinates(action.payload, state);
    case LEAVE_APPROVE_AS_IS:
      return setLeavesApproveAsIs(action.payload, state);
    case GET_REQUEST_INFO:
      return getRequestInfo(action.payload, state);
    case CANCEL_REQUEST:
      return setCancelRequest(action.payload, state);
    default:
      return state;
  }
};
