/* eslint-disable react/prop-types */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { Location, navigate } from '@reach/router';
import moment from 'moment';

import UserContext from '../../../context/userContext';

import { updateRequestHistoryApi } from '../../../utils/APIs';

function RequestEmailAction(props) {
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const { state: userState } = useContext(UserContext);
  const user = userState.isImpersonatingUser ? userState.asUser : userState.user;
  const { requestId, submitted, historyData } = props;
  const nowMoment = moment();
  const submittedMoment = moment(submitted);

  function navigateAndSendErrorAlert(stateData) {
    navigate(`/existing-requests`, {
      state: {
        ...stateData,
        showAlert: true,
        alertType: 'danger',
        alertMsg: 'Something went wrong with the request! Please try again later.'
      }
    });
  }

  function sendRequestUpdate(locationData) {
    setIsSendingEmail(true);

    /*
     * API request to request status update.
     * In case that the request failed or the response is not success,
     * navigate back to existing-requests page and display error Alert.
     */
    updateRequestHistoryApi(user.Email, requestId, 'requested_status_update')
      .then(res => {
        const success = Boolean(res.status);

        setIsSendingEmail(false);

        if (success) {
          setIsDone(true);

          setTimeout(() => {
            setIsDone(false);
          }, 1500);
        } else {
          navigateAndSendErrorAlert(locationData.state);
        }
      })
      .catch(() => {
        navigateAndSendErrorAlert(locationData.state);
      });
  }

  function checkHistoryForUpdateRequest() {
    const hasUpdateRequest = historyData.find(history => {
      const historyDateMoment = moment(history.historyDate);

      return (
        history.action === 'requested_status_update' && nowMoment.isAfter(historyDateMoment, 'day')
      );
    });

    return Boolean(hasUpdateRequest);
  }

  /**
   * Check if the submitted date is past 1 day or the history list doesn't have
   * an entry with action 'requested_update_status' with its date past 1 day.
   * If both true, display the request udpate link.
   */
  const hasRequestUpdateInHistory = checkHistoryForUpdateRequest();
  const shouldDisplayUpdateLink =
    nowMoment.isAfter(submittedMoment, 'day') && !hasRequestUpdateInHistory;

  return shouldDisplayUpdateLink ? (
    <div>
      <Location>
        {({ location }) => (
          <button
            type="button"
            title="Click to request email update"
            className={classNames('request-action', 'request-action--email', {
              'request-action--hidden': isSendingEmail || isDone
            })}
            onClick={() => sendRequestUpdate(location)}
          >
            Request a status update »
          </button>
        )}
      </Location>
      {isSendingEmail && <p>Sending Email...</p>}
      {isDone && <p style={{ color: '#22bf54' }}>Sent!</p>}
    </div>
  ) : null;
}

export default RequestEmailAction;
