/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { navigate } from '@reach/router';
import { DataTable } from 'carbon-components-react';

import Badges from '../Badges/Badges';
import RTODataTable from '../RTODataTable/RTODataTable';

import UserContext from '../../../context/userContext';
import LeavesContext from '../../../context/leavesContext';
import BackButton from '../../generic/BackButton/BackButton';

import LeavesSummaryTable from '../LeavesSummaryTable/LeavesSummaryTable';

import * as helper from '../../../utils/CoreHelpers';

import { getLeavesSummaryApi, getLeavesRequestApi } from '../../../utils/APIs';

const { TableHeader, TableRow, TableCell } = DataTable;

function ExistingRequestsTable(props) {
  const [currentRequests, setCurrentRequests] = useState([]);
  const [requestorName, setRequestorName] = useState(null);
  const { state: leavesState, dispatch } = useContext(LeavesContext);
  const { state: userState } = useContext(UserContext);
  const { uri, location, dashboard, employeeEmail } = props;
  const prevLocation = uri ? `/${uri.split('/')[1]}` : '';
  const [currentYear, setCurrentYear] = useState(null);

  const user = userState.isImpersonatingUser ? userState.asUser : userState.user;
  const isUser = role => user && user.Roles.includes(role);
  const isVA = dashboard === 'vacation-approver' && isUser('Vacation Approver');

  const tableHeaders = [
    { key: 'requestStatusString', header: 'Status' },
    { key: 'submitted', header: 'Submitted' },
    { key: 'requestType', header: 'Type' },
    { key: 'fromDate', header: 'Date(s)' },
    { key: 'workDays', header: 'Number of Days Off' },
    { key: 'reason', header: 'Reason' },
    { key: 'requestorComments', header: 'Comments' }
  ];
  const colspanTotal = tableHeaders.length;

  useEffect(() => {
    const customHeaderSort = document.querySelector('button.js-init-sort');

    if (customHeaderSort) {
      customHeaderSort.click();

      setTimeout(() => {
        customHeaderSort.click(); // similar implementation with Existing Request Table
      }, 100);
    }
  }, []);

  // Get Leaves Request Details for individual user
  useEffect(() => {
    if (leavesState.leavesRequests && leavesState.leavesRequests.length > 0) {
      setCurrentRequests(leavesState.leavesRequests);
    }

    return () => setCurrentRequests([]);
  }, [leavesState.leavesRequests]);

  // Get Leaves Summary for individual user
  useEffect(() => {
    async function getSummary() {
      if (isVA) {
        const summary = await getLeavesSummaryApi(employeeEmail).then(res => res);

        if (summary.status === 1) {
          dispatch({ type: 'GET_LEAVES_SUMMARY', payload: summary.data });
        }
      }
    }

    getSummary();
  }, []);

  useEffect(() => {
    async function getDetails() {
      if (isVA) {
        const leaveDetails = await getLeavesRequestApi(employeeEmail, currentYear);

        if (leaveDetails.status === 1) {
          dispatch({ type: 'GET_LEAVES_REQUESTS', payload: leaveDetails.data });
        }
      }
    }
    getDetails();
  }, [currentYear]);

  useEffect(() => {
    if (location) {
      const fullName = location.state && location.state.fullName;

      if (!fullName) {
        navigate(prevLocation);

        return;
      }

      setRequestorName(fullName);
    }
  }, [location]);

  function doCustomTableInitialSort() {
    const leavesRequests = [...leavesState.leavesRequests].sort((a, b) => {
      if (a.toDate > b.toDate) return -1;
      if (a.toDate < b.toDate) return 1;
      return 0;
    });

    return leavesRequests;
  }

  useEffect(() => {
    if (leavesState.leavesRequests.length > 0) {
      const initialSortedRequests = doCustomTableInitialSort();

      setCurrentRequests(initialSortedRequests);
    }
  }, [leavesState.leavesRequests]);

  function doCustomTableSearch(event) {
    if (leavesState.leavesRequests.length > 0) {
      const currentSortedData = doCustomTableInitialSort();
      const inputValue = event.target.value;

      if (inputValue === '') {
        setCurrentRequests(currentSortedData);

        return;
      }

      const searchables = currentSortedData.filter(request => {
        const fullText = `${request.requestStatusString}
        ${moment(request.submitted).fromNow()}
        ${moment(request.submitted).format('ddd, M/D/YY')}
        ${request.requestRefNum}
        ${request.requestType}
        ${moment(request.fromDate).format('ddd, M/D')}
        ${moment(request.toDate).format('ddd, M/D/YY')}
        ${request.workDays > 1 ? `${request.workDays} work days` : ''}
        ${request.halfDayPeriod !== '' ? `${request.halfDayPeriod.toLowerCase()} only` : ''}
        ${request.reason}
        ${request.requestorComments}`;

        return fullText.toLowerCase().includes(inputValue.toLowerCase());
      });

      setCurrentRequests(searchables);
    }
  }

  const CellRenders = ({ cellId, cellValue, rawData }) => {
    switch (cellId) {
      case 'requestStatusString':
        return <Badges text={cellValue} />;
      case 'submitted': {
        return (
          <div>
            <p>
              <span>
                {moment
                  .utc(cellValue)
                  .tz(helper.getCurrentLocationTimezone())
                  .format('ddd, MM/DD/YY hh:mm A')}{' '}
                {moment.tz(helper.getCurrentLocationTimezone()).zoneAbbr()}
              </span>
            </p>
            <span>{rawData.requestRefNum}</span>
          </div>
        );
      }
      case 'fromDate':
        return (
          <>
            <div className="table-cell__request-dates">
              {rawData.workDays > 1 ? (
                <>
                  <span>{moment(cellValue).format('ddd, M/D')}</span>
                  <span>&rarr;</span>
                  <span>{moment(rawData.toDate).format('ddd, M/D/YY')}</span>
                </>
              ) : (
                <span>{moment(cellValue).format('ddd, M/D/YY')}</span>
              )}
            </div>

            {rawData.halfDayPeriod ? (
              <div>
                {rawData.halfDayPeriod !== '' && `(${rawData.halfDayPeriod.toLowerCase()} only)`}
              </div>
            ) : (
              ''
            )}
          </>
        );
      case 'workDays':
        return (
          <div className="table-cell__request-days-count">
            {rawData.halfDayPeriod ? <span>0.5</span> : <span>{`${rawData.workDays}`}</span>}
          </div>
        );
      default:
        return cellValue;
    }
  };

  return (
    <>
      {location && <BackButton label="Back to list" />}

      {isVA && <LeavesSummaryTable handleYear={setCurrentYear} />}

      <RTODataTable
        title={`Request Details ${requestorName ? `for ${requestorName}` : ''}`}
        tableHeaders={tableHeaders}
        tableRows={currentRequests}
        withPagination={!location}
        withSorting
        withSearch
        customSearchFunction={event => doCustomTableSearch(event)}
        headersRender={(headers, getHeaderProps) =>
          headers.map(header => {
            return (
              <TableHeader
                className={header.key === 'fromDate' ? 'js-init-sort' : ''} // hardcoded based on initial sort implemented
                key={header.key}
                {...getHeaderProps({ header })}
              >
                {header.header}
              </TableHeader>
            );
          })
        }
        rowsRender={rows => {
          return rows.length > 0 ? (
            rows.map(row => {
              const rawData = currentRequests.find(data => data.id === row.id);

              if (!rawData) return null;

              const isRejectedOrCancelled =
                rawData.requestStatusString.search(/Rejected|Cancelled/) > -1;
              const isAwaiting = rawData.requestStatusString.search(/Awaiting/) > -1;
              const today = moment().startOf('day');
              const toDateParsed = moment.utc(rawData.toDate).startOf('day');
              let cellClass = '';

              if (isAwaiting) {
                cellClass = 'rto-data-table__cell--awaiting';
              }

              if (toDateParsed.isBefore(today) || isRejectedOrCancelled) {
                cellClass = 'rto-data-table__cell--past';
              }

              return (
                <TableRow key={row.id}>
                  {row.cells.map(cell => {
                    const cellId = cell.id.split(':')[1];

                    let cellValue = cell.value;

                    if (cellId === 'requestStatusString' && cellValue.search(/Awaiting/) > -1) {
                      cellValue =
                        cellValue.search(/Vacation/) > -1
                          ? 'Pending Approval - Vacation Approver'
                          : 'Pending Approval - Department Lead';
                    }

                    return (
                      <TableCell key={cell.id} className={cellClass}>
                        <CellRenders
                          rowId={row.id}
                          cellId={cellId}
                          cellValue={cellValue}
                          rawData={rawData}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={colspanTotal}>No Requests found.</TableCell>
            </TableRow>
          );
        }}
      />
    </>
  );
}

export default ExistingRequestsTable;
