/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { Tooltip } from 'carbon-components-react';
import UserContext from '../../../context/userContext';
import * as helper from '../../../utils/CoreHelpers';

import './Navigation.scss';

const tooltipProps = () => ({
  showIcon: true,
  iconDescription: 'Helpful Information',
  tabIndex: 0
});

const dashboardTooltipText = role => {
  switch (role) {
    case 'Vacation Approver':
      return 'You can view RTO summaries of employees for whom you are the vacation approver.';
    case 'Department Lead':
      return 'This dashboard displays all RTO requests of all employees under your department.';
    case 'HR':
      return "As an HR employee you can view all employees' requests";
    default:
      return "As an administrator you can view all employees' requests";
  }
};

function Navigation(props) {
  const { state } = useContext(UserContext);
  const { locationState } = props;
  const user = state.isImpersonatingUser ? state.asUser : state.user;

  const Navlink = navLinkProps => (
    // Check if current page matches path then add active class to link.
    <Link
      {...navLinkProps}
      state={
        locationState !== null
          ? { key: locationState.key, impersonateUser: locationState.impersonateUser }
          : null
      }
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        return {
          className:
            isCurrent || isPartiallyCurrent
              ? 'navigation__link navigation__link--active'
              : 'navigation__link'
        };
      }}
    />
  );

  const rolesWithDashboard = helper.setDashboardPaths(user.Roles);

  return (
    <nav className="navigation">
      <ul>
        <li>
          <Navlink to="/existing-requests">Existing Requests</Navlink>
        </li>
        <li>
          <Navlink to="/submit-request">Submit a New Request</Navlink>
        </li>
        {rolesWithDashboard.map(role => {
          return (
            <React.Fragment key={role.path}>
              <li>
                <Navlink to={`/${role.path}`}>{`${role.name} Dashboard`}</Navlink>
                <Tooltip {...tooltipProps()}>
                  <p>{dashboardTooltipText(role.name)}</p>
                </Tooltip>
              </li>
            </React.Fragment>
          );
        })}
      </ul>
    </nav>
  );
}

Navigation.propTypes = {
  locationState: PropTypes.shape({
    impersonateUser: PropTypes.string
  })
};

Navigation.defaultProps = {
  locationState: {}
};

export default Navigation;
