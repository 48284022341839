/* eslint-disable import/prefer-default-export */
// USER
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_ACTIVE_USER = 'SET_ACTIVE_USER';
export const IMPERSONATE_USER = 'IMPERSONATE_USER';
export const END_IMPERSONATION = 'END_IMPERSONATION';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const GET_GRAIL_STATUS = 'GET_GRAIL_STATUS';

// LEAVES
export const GET_LEAVES_SUMMARY = 'GET_LEAVES_SUMMARY';
export const GET_LEAVES_REQUESTS = 'GET_LEAVES_REQUESTS';
export const GET_LEAVES_AVAILABLE = 'GET_LEAVES_AVAILABLE';
export const GET_LEAVES_AWAITING = 'GET_LEAVES_AWAITING';
export const GET_SUBORDINATES = 'GET_SUBORDINATES';
export const LEAVE_APPROVE_AS_IS = 'LEAVE_APPROVE_AS_IS';
export const GET_REQUEST_INFO = 'GET_REQUEST_INFO';
export const CANCEL_REQUEST = 'CANCEL_REQUEST';

// REQUEST
export const SET_REQUESTOR_EMAIL = 'SET_REQUESTOR_EMAIL';
export const SET_REQUESTOR_JSON = 'SET_REQUESTOR_JSON';
export const SET_DATES_JSON = 'SET_DATES_JSON';
export const SET_WORK_DAYS = 'SET_WORK_DAYS';
export const SET_REQUEST_TYPES = 'SET_REQUEST_TYPES';
export const SET_FROM_DATE = 'SET_FROM_DATE';
export const SET_TO_DATE = 'SET_TO_DATE';
export const SET_HALFDAY_PERIOD = 'SET_HALFDAY_PERIOD';
export const SET_REASON = 'SET_REASON';
export const SET_TEAM_MEMBERS = 'SET_TEAM_MEMBERS';
export const SET_REQUESTOR_COMMENTS = 'SET_REQUESTOR_COMMENTS';
