/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import React, { useState, useContext, useEffect, useReducer } from 'react';
import moment from 'moment-timezone';
import { Link } from '@reach/router';
import { InlineLoading } from 'carbon-components-react';
import CaretLeft20 from '@carbon/icons-react/es/caret--left/20';

import UserContext from '../../../context/userContext';
import LeavesContext, { initialState } from '../../../context/leavesContext';
import LeavesReducer from '../../../context/leavesReducer';

import Alerts from '../../modules/Alerts/Alerts';
import RequestDetailsInfoTable from '../../modules/RequestDetailsInfoTable/RequestDetailsInfoTable';
import LeavesSummaryTable from '../../modules/LeavesSummaryTable/LeavesSummaryTable';
import ExistingRequestsTable from '../../modules/ExistingRequestsTable/ExistingRequestsTable';
import ApprovalForm from '../../modules/ApprovalForm/ApprovalForm';
import Calendar from '../../modules/Calendar/Calendar';

import * as helper from '../../../utils/CoreHelpers';

import {
  getLeavesRequestApi,
  getLeavesSummaryApi,
  getRequestInfoApi,
  updateRequestStatusApi,
  updateRequestHistoryApi
} from '../../../utils/APIs';
import Copy from '../../../utils/Copy';

import './ApprovalPage.scss';

function ApprovalPage(props) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasViewed, setHasViewed] = useState(false);
  const [isNotAllowed, setIsNotAllowed] = useState(false);
  const [isStepOutOfOrder, setIsStepOutOfOrder] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [isYetToBeApproved, setIsYetToBeApproved] = useState(true);
  const [alertStatus, setAlertStatus] = useState('default');
  const [alertText, setAlertText] = useState(
    `This request requires you to make an approval decision.`
  );
  const [alertActive, setAlertActive] = useState(true);
  const [backURL, setBackURL] = useState('/');
  const [state, dispatch] = useReducer(LeavesReducer, initialState);
  const { state: userState } = useContext(UserContext);
  const user = userState.isImpersonatingUser ? userState.asUser : userState.user;
  // eslint-disable-next-line react/prop-types
  const { flag, requestId, location } = props;
  const thisRequest = state.requestInfo;

  useEffect(() => {
    // Get details of this request.
    getRequestInfoApi(requestId).then(req => {
      const { status, msg, data } = req;

      // Check if RequestId exists in DB.
      if (status === 0 && msg === 'Request not found.') {
        setHasLoaded(true);
        return;
      }

      // If RequestId exists,
      if (data) {
        const flowStep = data.requestFlowStep;
        const isUserVacationApprover = data.vacationApprover.includes(user.Email);
        const isUserDepartmentLeadApprover = data.departmentLeadApprover.includes(user.Email);
        // Special case when the user is Vacation Approver and Department lead at the same time.
        const isUserApproverAndLead = isUserVacationApprover && isUserDepartmentLeadApprover;

        // Check if the user is allowed to view the approval page
        if (!isUserVacationApprover && !isUserDepartmentLeadApprover) {
          setIsNotAllowed(true);
          setHasLoaded(true);
          return;
        }

        // If allowed,
        // Check if flag is approval `as-is`
        if (flag === 'as-is') {
          // Check if request is still waiting approval
          if (data.requestStatusString.search(/Awaiting/) !== -1) {
            // Check flow step and approver's identity
            const requestUpdateStatus =
              flowStep === 0 ? 'Awaiting Department Lead Decision' : 'Approved';

            if (
              (flowStep === 0 && isUserVacationApprover) ||
              (flowStep === 1 && isUserDepartmentLeadApprover)
            ) {
              // Temporarily hide the Alert component to not show the transition to Approved state.
              setAlertActive(false);

              updateRequestStatusApi(user.Email, data.id, requestUpdateStatus).then(res => {
                if (res.status === 1) {
                  setAlertStatus('approved');

                  if (isUserDepartmentLeadApprover) {
                    setAlertText(Copy.approval_as_is_success_by_DL());
                  } else if (isUserVacationApprover) {
                    if (data.requestType === 'Work From Home') {
                      setAlertText(Copy.approval_as_is_success_by_VA_WFH());
                    } else {
                      setAlertText(Copy.approval_as_is_success_by_VA());
                    }
                  }

                  setIsYetToBeApproved(false);
                } else {
                  setAlertStatus('rejected');
                  setAlertText(Copy.approval_fail());
                }

                setAlertActive(true);
              });
            }
          }
        }

        // Else, if flag is approval `form`
        // Then set returned request data to state
        dispatch({ type: 'GET_REQUEST_INFO', payload: data });

        // Then, Fetch requests list and request summary count of the user.
        // Then, set to state
        Promise.all([
          getLeavesRequestApi(data.requestorEmail),
          getLeavesSummaryApi(data.requestorEmail)
        ]).then(res => {
          const request = res[0];
          const summary = res[1];
          if (request.status === 1 && summary.status === 1) {
            dispatch({ type: 'GET_LEAVES_REQUESTS', payload: request.data });
            dispatch({ type: 'GET_LEAVES_SUMMARY', payload: summary.data });
          }

          // Post 'viewed' history update
          if (!hasViewed) {
            updateRequestHistoryApi(user.Email, requestId, 'viewed').then(() => setHasViewed(true));
          }
        });

        if (hasViewed) {
          // Check if the request is still `Awaiting Decision`
          if (thisRequest.requestStatusString.search(/Awaiting/) !== -1) {
            // Check if the logged user is the expected approver
            // Then, setApprover to true
            // Else, check if the logged user is a Department Approver
            // Then, setOutOfOrder to true
            if (
              (flowStep === 0 && isUserVacationApprover) ||
              (flowStep === 1 && (isUserVacationApprover || isUserDepartmentLeadApprover)) ||
              (flowStep === 2 && isUserApproverAndLead)
            ) {
              setIsApprover(true);
            }

            // If requestStepFlow is 0 but user is DL (and not the special case VA + DL)
            // Then, setOutOfOrder to true
            if (flowStep === 0 && isUserDepartmentLeadApprover && !isUserApproverAndLead) {
              setIsStepOutOfOrder(true);
            }
          } else {
            setIsYetToBeApproved(false);
          }

          setHasLoaded(true);

          // Check request status for display
          displayAlertText();
        }
      }
    });
  }, [hasViewed]);

  useEffect(() => {
    // if Location State is not null and contains dashboard value,
    // then set its dashboard path state,
    // else, it must be routed from email notification
    // then, get from the url params
    if (location.state !== null && location.state.dashboard) {
      setBackURL(location.state.dashboard);
    } else {
      const paramString = window.location.search.split('?')[1];
      const urlParams = new URLSearchParams(paramString);
      const role = urlParams.get('role');

      setBackURL(role ? `/${role}` : '/');
    }
  }, []);

  function formatDate(date) {
    return moment
      .utc(date)
      .tz(helper.getCurrentLocationTimezone())
      .format('ddd, M/D/YY [at] h:mm:ss a');
  }

  function displayAlertText() {
    const requestStatus = thisRequest.requestStatusString;
    const requestFlow = thisRequest.requestFlowStep;

    // filter history, remove all with view actions
    const filteredHistory = thisRequest.historyArray
      .slice()
      .reverse()
      .filter(history => history.action.toLowerCase() !== 'viewed');

    const historyAction = filteredHistory[0];

    if (requestFlow !== 0) {
      // set alert status to warning based on previous code
      setAlertStatus('warning');

      const approverName =
        user.Email === historyAction.eventEmail ? 'You have' : `${historyAction.userFullName} has`;

      // status are based on
      // https://codeandtheory.atlassian.net/wiki/spaces/RR/pages/853411545/G4+Status
      switch (requestStatus.toLowerCase()) {
        case 'awaiting department lead decision': {
          setAlertText(
            `${approverName} approved this request and the
            department lead has been emailed for their approval.`
          );
          return true;
        }
        case 'acknowledged': {
          setAlertText(Copy.view_request_acknowledged_SL(formatDate(thisRequest.submitted)));
          return true;
        }
        case 'approved':
        case 'rejected':
        case 'cancelled': {
          setAlertText(
            Copy.view_request(
              approverName,
              requestStatus.toLowerCase(),
              formatDate(historyAction.historyDate)
            )
          );
          return true;
        }
        default:
          return false;
      }
    }

    return false;
  }

  if (!hasLoaded) {
    return <InlineLoading style={{ margin: '1rem 0' }} description="Loading..." />;
  }

  if (!isNotAllowed && hasLoaded && state.requestInfo === null) {
    return <Alerts type="warning" message={Copy.request_not_found()} />;
  }

  return (
    <div className="approval-page">
      <Link
        to={backURL}
        state={{ ...location.state }}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <CaretLeft20 fill="#3186c3" />
        <span>Back to dashboard</span>
      </Link>
      <hr style={{ marginBottom: '30px' }} />
      {isNotAllowed && <Alerts type="rejected" message={Copy.access_denied()} />}
      {isYetToBeApproved && !isApprover && isStepOutOfOrder && (
        <Alerts type="rejected" message={Copy.out_of_order()} />
      )}
      {(!isYetToBeApproved || isApprover) && (
        <>
          <Alerts active={alertActive} type={alertStatus} message={alertText} />
          <h1 className="approval-page__header">Request Details</h1>
          <LeavesContext.Provider value={{ state, dispatch }}>
            <RequestDetailsInfoTable />
            <LeavesSummaryTable />
            <ExistingRequestsTable />
            {user.Role === 'Department Lead' && (
              <Calendar user={user} request={state.requestInfo} />
            )}
            {flag === 'form' && isYetToBeApproved && !isStepOutOfOrder && (
              <ApprovalForm request={state.requestInfo} />
            )}
          </LeavesContext.Provider>
        </>
      )}
    </div>
  );
}

export default ApprovalPage;
