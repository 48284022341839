import React from 'react';
// import Alerts from '../modules/Alerts/Alerts';
// import ErrorBoundary from '../../utils/ErrorBoundary';

function Index() {
  return (
    <div>
      {/* <ErrorBoundary>
        <Alerts type="approved" />
      </ErrorBoundary> */}
      <p>Hello, you awesome developer! Woot!</p>
    </div>
  );
}

export default Index;
