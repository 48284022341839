import { createContext } from 'react';

export const initialState = {
  leavesRequests: [],
  leavesSummary: [],
  leavesAwaitingApproval: [],
  subordinates: [],
  requestInfo: null
};

const leavesContext = createContext(initialState);

export default leavesContext;
