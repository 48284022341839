/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MainContainer = styled.main`
  padding: 40px 15px;

  @media (min-width: 768px) {
    padding: 40px 25px;
  }

  @media (min-width: 960px) {
    padding: 50px;
  }
`;

function Main({ children }) {
  return <MainContainer>{children}</MainContainer>;
}

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
