import { format, eachDay, isWeekend } from 'date-fns';

import {
  SET_REQUESTOR_EMAIL,
  SET_REQUESTOR_JSON,
  SET_DATES_JSON,
  SET_WORK_DAYS,
  SET_REQUEST_TYPES,
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_HALFDAY_PERIOD,
  SET_REASON,
  SET_TEAM_MEMBERS,
  SET_REQUESTOR_COMMENTS
} from './types';

// Returns an array of dates between the two dates
function getDatesBetween(startDate, endDate, excludedDatesArray) {
  const dates = [];

  if (endDate) {
    const days = eachDay(startDate, endDate);
    days.forEach(d => {
      const isDateExcluded = excludedDatesArray.includes(format(d, 'YYYY-MM-DD'));

      if (!isWeekend(d) && !isDateExcluded) {
        dates.push(format(d, 'M/D/YYYY'));
      }
    });
  }

  return dates;
}

const setRequestorEmail = (value, state) => {
  return {
    ...state,
    requestorEmail: value
  };
};

const setRequestorJson = (value, state) => {
  return {
    ...state,
    requestorJson: JSON.stringify(value)
  };
};

const setDatesJson = (excludedDatesArray, state) => {
  const dates = getDatesBetween(state.fromDate, state.toDate, excludedDatesArray);

  return {
    ...state,
    datesJson: JSON.stringify(dates)
  };
};

const setWorkDays = (value, state) => {
  const days = JSON.parse(state.datesJson).length;
  const numDays = days === 1 && state.halfDayPeriod !== '' ? 0.5 : days;

  return {
    ...state,
    // eslint-disable-next-line no-restricted-globals
    workDays: isNaN(numDays) ? 0 : numDays
  };
};

const setRequestType = (value, state) => {
  return {
    ...state,
    requestType: value
  };
};

const setFromDate = (value, state) => {
  return {
    ...state,
    fromDate: value
  };
};

const setToDate = (value, state) => {
  return {
    ...state,
    toDate: state.requestType === 'Sick/Emergency' ? state.fromDate : value
  };
};

const setHalfDayPeriod = (value, state) => {
  return {
    ...state,
    halfDayPeriod: value
  };
};

const setReason = (value, state) => {
  return {
    ...state,
    reason: value
  };
};

const setRelevantTeamMembersEmails = (value, state) => {
  const emails = [];
  // eslint-disable-next-line consistent-return
  value.forEach(v => {
    if (v.value !== undefined) {
      return emails.push(JSON.stringify(v.value).replace(/"/g, ''));
    }
  });

  return {
    ...state,
    relevantTeamMembersEmails: emails.join(',')
  };
};

const setRequestorComments = (value, state) => {
  return {
    ...state,
    requestorComments: value
  };
};

export default (state, action) => {
  switch (action.type) {
    case SET_REQUESTOR_EMAIL:
      return setRequestorEmail(action.payload, state);
    case SET_REQUESTOR_JSON:
      return setRequestorJson(action.payload, state);
    case SET_DATES_JSON:
      return setDatesJson(action.payload, state);
    case SET_WORK_DAYS:
      return setWorkDays(action.payload, state);
    case SET_REQUEST_TYPES:
      return setRequestType(action.payload, state);
    case SET_FROM_DATE:
      return setFromDate(action.payload, state);
    case SET_TO_DATE:
      return setToDate(action.payload, state);
    case SET_HALFDAY_PERIOD:
      return setHalfDayPeriod(action.payload, state);
    case SET_REASON:
      return setReason(action.payload, state);
    case SET_TEAM_MEMBERS:
      return setRelevantTeamMembersEmails(action.payload, state);
    case SET_REQUESTOR_COMMENTS:
      return setRequestorComments(action.payload, state);
    default:
      return state;
  }
};
