/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';
import { Router, Redirect } from '@reach/router';

import Home from '../components/pages/Index';
import RequestFormPage from '../components/pages/RequestFormPage/RequestFormPage';
import ExistingRequests from '../components/pages/ExistingRequests/ExistingRequests';
import Dashboard from '../components/pages/Dashboard/Dashboard';
import ApprovalPage from '../components/pages/ApprovalPage/ApprovalPage';
import NoMatch from '../components/pages/NoMatch';
import Login from '../components/pages/Login/Login';

import ExistingRequestsTable from '../components/modules/ExistingRequestsTable/ExistingRequestsTable';
import SummaryEmployeeTable from '../components/modules/SummaryEmployeeTable/SummaryEmployeeTable';

import UserContext from '../context/userContext';
import * as helper from '../utils/CoreHelpers';

const renderRoutes = ({ origin }) => {
  const { state } = useContext(UserContext);
  const user = state.isImpersonatingUser ? state.asUser : state.user;
  const dashboardPaths = helper.setDashboardPaths(user.Roles);

  return (
    <Router>
      <Redirect noThrow from="/" to={origin !== '/' ? origin : '/existing-requests'} />
      <Home path="/" />
      <ExistingRequests path="/existing-requests" />
      <ExistingRequests path="/existing-requests/impersonate/:impersonateUser" />
      <RequestFormPage path="/submit-request" />
      {dashboardPaths.map(path => (
        <Dashboard key={path.path} path={`/${path.path}`}>
          <SummaryEmployeeTable path="/" />
          <ExistingRequestsTable dashboard={path.path} path="employee/:employeeEmail" />
        </Dashboard>
      ))}
      <ApprovalPage path="/approval-page/:flag/:requestId" />
      <Login path="/login" />
      <NoMatch default />
    </Router>
  );
};

export default renderRoutes;
