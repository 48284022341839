import React, { useEffect, useContext } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { navigate } from '@reach/router';
import jwtDecode from 'jwt-decode';

import UserContext from '../../../context/userContext';
import { loginUserApi } from '../../../utils/APIs';
import * as helper from '../../../utils/CoreHelpers';
import Copy from '../../../utils/Copy';

import Alerts from '../../modules/Alerts/Alerts';

import './_Login.scss';

const Login = () => {
  const { state, dispatch } = useContext(UserContext);

  useEffect(() => {
    // Checked if User is already logged in then redirect back to home.
    if (state.isLogged) {
      navigate('/');
    }

    // Check if the redirection to login is caused by an API error
    const error = helper.getErrorCookie();

    if (error) {
      dispatch({
        type: 'SET_LOGIN_ERROR',
        payload: {
          msg: Copy[error](),
          state: true
        }
      });

      helper.removeErrorCookie();
    }
  }, [state.isLogged]);

  function handleLogin(userData) {
    loginUserApi(userData).then(user => {
      if (user.code >= 400) {
        dispatch({
          type: 'SET_LOGIN_ERROR',
          payload: {
            msg: Copy[user.msg](userData.email),
            state: true
          }
        });
      } else {
        helper.setCookie(userData.token);
        helper.removeErrorCookie();

        dispatch({
          type: 'SET_ACTIVE_USER',
          payload: user
        });

        dispatch({
          type: 'SET_LOGIN_ERROR',
          payload: {
            msg: '',
            state: false
          }
        });

        navigate('/existing-requests');
      }
    });
  }

  function handleGoogleLogin(response) {
    const res = jwtDecode(response.credential); // Handles the parsing of the JWT token for sign in
    handleLogin({
      email: res.email,
      token: response.credential
    });
  }

  return (
    <div className="login">
      {state.hasLoginError && <Alerts type="rejected" message={state.loginError} />}

      <div className="login__box">
        <strong>Login</strong>
        <hr />
        <GoogleLogin
          onSuccess={credResponse => {
            handleGoogleLogin(credResponse);
          }}
        />
      </div>
    </div>
  );
};

export default Login;
