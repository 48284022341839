import React from 'react';

function NoMatch() {
  return (
    <div>
      <h1>404 error</h1>
      <p>Your requested page cannot be found.</p>
    </div>
  );
}

export default NoMatch;
