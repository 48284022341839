/* eslint-disable react/prop-types */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { Location, navigate } from '@reach/router';
import { InlineLoading } from 'carbon-components-react';

import UserContext from '../../../context/userContext';
import LeavesContext from '../../../context/leavesContext';

import { updateRequestStatusApi } from '../../../utils/APIs';

function RequestCancelAction(props) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isSucess, setIsSucess] = useState(false);
  const { state, dispatch } = useContext(LeavesContext);
  const { state: userState } = useContext(UserContext);
  const user = userState.isImpersonatingUser ? userState.asUser : userState.user;
  const { requestId } = props;

  function cancelRequest(locationData) {
    const successCopy = 'That request has successfully been canceled.';
    const errorCopy = 'Something went wrong! Please try again later.';

    setIsCancelling(true);

    // API request to update status to 'Cancelled'.
    updateRequestStatusApi(user.Email, requestId, 'Cancelled')
      .then(res => {
        setIsSucess(true);

        setTimeout(() => {
          setIsCancelling(false);
          setShowConfirm(false);

          if (res.status === 1) {
            const updatedState = state.leavesRequests.map(request => {
              if (request.id === requestId) {
                request.requestStatusString = 'Cancelled';
              }

              return request;
            });

            dispatch({ type: 'CANCEL_REQUEST', payload: updatedState });
          }

          navigate(`/existing-requests`, {
            state: {
              ...locationData.state,
              showAlert: true,
              alertType: res.status === 1 ? 'success' : 'danger',
              alertMsg: res.status === 1 ? successCopy : errorCopy
            }
          });
        }, 300);
      })
      .catch(() => {
        navigate(`/existing-requests`, {
          state: {
            ...locationData.state,
            showAlert: true,
            alertType: 'danger',
            alertMsg: errorCopy
          }
        });
      });
  }

  return (
    <div>
      <button
        type="button"
        title="Click to cancel this request"
        className={classNames('request-action', 'request-action--cancel', {
          'request-action--hidden': showConfirm
        })}
        onClick={() => {
          setShowConfirm(true);
        }}
      >
        <span>Cancel »</span>
      </button>
      {showConfirm && !isCancelling ? (
        <div className="confirm">
          <p className="confirm__text">Are you sure?</p>
          <Location>
            {({ location }) => (
              <a
                className="confirm__option confirm__option--yes"
                onClick={() => cancelRequest(location)}
              >
                Yes
              </a>
            )}
          </Location>
          <a
            className="confirm__option confirm__option--no"
            onClick={() => {
              setShowConfirm(false);
            }}
          >
            No
          </a>
        </div>
      ) : null}
      {isCancelling && (
        <InlineLoading
          iconDescription="Cancel Loading Indicator"
          description="Cancelling Request..."
          success={isSucess}
        />
      )}
    </div>
  );
}

export default RequestCancelAction;
