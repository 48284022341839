/* eslint-disable import/prefer-default-export */
import Cookies from 'js-cookie';
import moment from 'moment-timezone';

export function setCookie(token) {
  Cookies.set('rto_log_session', token, { expires: 7 });
}

export function getCookie() {
  return Cookies.get('rto_log_session');
}

export function removeCookie() {
  return Cookies.remove('rto_log_session');
}

export function setErrorCookie(error) {
  Cookies.set('rto_api_error', error, { expires: 7 });
}

export function getErrorCookie() {
  return Cookies.get('rto_api_error');
}

export function removeErrorCookie() {
  return Cookies.remove('rto_api_error');
}

export function isUserFound(obj) {
  return (
    obj !== undefined &&
    Object.values(obj).indexOf('Access denied (status code GRAIL-001).') === -1 &&
    Object.values(obj).indexOf('User not found (status code GRAIL-003).') === -1
  );
}

export function formatListOfNames(namesArray) {
  return namesArray.length > 1
    ? namesArray
        .map((name, index) => {
          if (index > 0 && index < namesArray.length - 1) {
            return `, ${name}`;
          }

          if (index === namesArray.length - 1) {
            return ` and ${name}`;
          }

          return name;
        })
        .join('')
    : namesArray;
}

export function getCurrentLocationTimezone() {
  return moment.tz.guess(true);
}

export function isObjectEmpty(obj) {
  Object.keys(obj).forEach(key => {
    if (obj.hasOwnProperty.call(key)) return false;
    return true;
  });
}

export function setDashboardPaths(userRoles) {
  const routes = [];
  const isUser = role => userRoles && userRoles.includes(role);

  if (isUser('Vacation Approver'))
    routes.push({ path: 'vacation-approver', name: 'Vacation Approver' });
  if (isUser('Department Lead')) routes.push({ path: 'department-lead', name: 'Department Lead' });
  if (isUser('HR')) routes.push({ path: 'hr', name: 'HR' });
  if (isUser('Admin')) routes.push({ path: 'admin', name: 'Admin' });

  return routes;
}

export function getDefaultWeekDay(type = 'first') {
  const date = type === 'last' ? moment().endOf('year') : moment().startOf('year');

  while (date.day() % 6 === 0) {
    if (type === 'last') date.subtract(1, 'day');
    if (type === 'first') date.add(1, 'day');
  }

  return new Date(date);
}
