/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect, useReducer } from 'react';
import { InlineLoading } from 'carbon-components-react';

import UserContext from '../../../context/userContext';
import LeavesContext, { initialState } from '../../../context/leavesContext';
import LeavesReducer from '../../../context/leavesReducer';

import AwaitingDecisionTable from '../../modules/AwaitingDecisionTable/AwaitingDecisionTable';

import { getAwaitingLeavesApi, getLeavesRequestApi } from '../../../utils/APIs';

function Dashboard(props) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [state, dispatch] = useReducer(LeavesReducer, initialState);
  const { state: userState } = useContext(UserContext);
  const { children, '*': relativePath } = props;
  const user = userState.isImpersonatingUser ? userState.asUser : userState.user;

  useEffect(() => {
    const { Email } = user;
    let isMounted = true;

    getAwaitingLeavesApi(Email).then(res => {
      if (isMounted && res) {
        dispatch({ type: 'GET_LEAVES_AWAITING', payload: res.data });
        setHasLoaded(true);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [userState]);

  useEffect(() => {
    if (relativePath) {
      const relativePaths = relativePath.split('/');
      const requestorEmail = relativePaths[relativePaths.length - 1];

      // Fetch all existing request from specific user.
      getLeavesRequestApi(requestorEmail).then(res => {
        dispatch({ type: 'GET_LEAVES_REQUESTS', payload: res.data });
      });
    }
  }, [relativePath]);

  if (!hasLoaded) {
    return <InlineLoading style={{ margin: '1rem 0' }} description="Loading..." />;
  }

  return (
    <LeavesContext.Provider value={{ state, dispatch }}>
      {state.leavesAwaitingApproval && state.leavesAwaitingApproval.length !== 0 && (
        <AwaitingDecisionTable />
      )}
      {state.subordinates && children}
    </LeavesContext.Provider>
  );
}

export default Dashboard;
