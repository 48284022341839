/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'carbon-components-react';
import Restart16 from '@carbon/icons-react/es/restart/16';

import RTOPagination from '../RTOPagination/RTOPagination';
import './RTODataTable.scss';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch
} = DataTable;

const defaultHeadersRender = (headers, getHeaderProps) => {
  return (
    <>
      {headers.map(header => (
        <TableHeader key={header.key} {...getHeaderProps({ header })}>
          {header.header}
        </TableHeader>
      ))}
    </>
  );
};

const defaultRowsRender = rows => {
  return rows.length > 0 ? (
    <>
      {rows.map(row => (
        <TableRow key={row.id}>
          {row.cells.map(cell => (
            <TableCell key={cell.id}>{cell.value}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  ) : (
    <TableRow>
      <TableCell>No data available</TableCell>
    </TableRow>
  );
};

function RTODataTable(props) {
  const {
    title,
    description,
    tableHeaders,
    tableRows,
    withSearch,
    customSearchFunction,
    withSorting,
    withRefresh,
    refreshAction,
    headersRender,
    rowsRender,
    ToolbarExtraComponents,
    paginationChange,
    totalRequestItems,
    pageSizeItem,
    customSort
  } = props;

  const showToolbar = ToolbarExtraComponents || withSearch;

  useEffect(() => {
    const currentToolbar = document.querySelector('.bx--toolbar-search-container-expandable');
    const currentSearchClose = currentToolbar.querySelector('.bx--search-close');

    const customBtnClick = () => {
      currentToolbar.classList.remove('bx--toolbar-search-container-active');
    };

    currentSearchClose.addEventListener('click', customBtnClick);

    return () => {
      currentSearchClose.removeEventListener('click', customBtnClick);
    };
  }, []);

  return (
    <section className="rto-data-table">
      <DataTable
        rows={tableRows}
        // rows={tableRows > 50 ? tableRows.slice(sliceStart, sliceEnd) : tableRows}
        headers={tableHeaders}
        isSortable={withSorting}
        sortRow={customSort}
        useZebraStyles
        size="short"
        render={({ rows, headers, getHeaderProps, onInputChange }) => {
          return (
            <TableContainer title={title} description={description}>
              <TableToolbar className={showToolbar ? 'bx--visually-hidden' : ''}>
                {ToolbarExtraComponents && (
                  <TableToolbarContent className="rto-data-table__toolbar-content filters-container">
                    {ToolbarExtraComponents}
                  </TableToolbarContent>
                )}

                {withSearch ? (
                  <TableToolbarContent className="rto-data-table__toolbar-content searchbar-container">
                    <TableToolbarSearch
                      placeHolderText="Type to filter"
                      onChange={customSearchFunction || onInputChange}
                    />
                  </TableToolbarContent>
                ) : null}

                {withRefresh ? (
                  <button
                    type="button"
                    className="rto-data-table__refresh"
                    onClick={e => refreshAction(e)}
                  >
                    <Restart16 />
                    <span> Refresh</span>
                  </button>
                ) : null}
              </TableToolbar>

              <div className="rto-data-table__wrapper">
                <Table>
                  <TableHead>
                    <TableRow>{headersRender(headers, getHeaderProps)}</TableRow>
                  </TableHead>
                  <TableBody>{rowsRender(rows)}</TableBody>
                </Table>
              </div>
            </TableContainer>
          );
        }}
      />

      {pageSizeItem > 10 ? (
        <div className="rto-data-table__pagination">
          <RTOPagination totalItems={totalRequestItems} pageChanged={paginationChange} />
        </div>
      ) : null}
    </section>
  );
}

RTODataTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  tableHeaders: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  tableRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  withSearch: PropTypes.bool,
  customSearchFunction: PropTypes.func,
  withSorting: PropTypes.bool,
  withRefresh: PropTypes.bool,
  refreshAction: PropTypes.func,
  headersRender: PropTypes.func,
  rowsRender: PropTypes.func,
  ToolbarExtraComponents: PropTypes.element,
  customSort: PropTypes.func
};

RTODataTable.defaultProps = {
  title: '',
  description: '',
  tableHeaders: [],
  tableRows: [],
  withSearch: false,
  withSorting: false,
  withRefresh: false,
  refreshAction: () => {
    return '';
  },
  headersRender: defaultHeadersRender,
  rowsRender: defaultRowsRender,
  ToolbarExtraComponents: null,
  customSort: (cellA, cellB, { sortDirection, sortStates }) => {
    if (sortDirection === sortStates.DESC) {
      return cellB.localeCompare(cellA);
    }

    return cellA.localeCompare(cellB);
  }
};

export default RTODataTable;
