/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useReducer } from 'react';
import { InlineLoading } from 'carbon-components-react';
import RequestForm from '../../modules/RequestForm/RequestForm';

import RequestContext, { initialState } from '../../../context/requestContext';
import RequestReducer from '../../../context/requestReducer';
import UserContext from '../../../context/userContext';

import { getGrailStatusApi, getUsersList } from '../../../utils/APIs';

function RequestFormPage(props) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [state, dispatch] = useReducer(RequestReducer, initialState);
  const { state: userState, dispatch: userDispatch } = useContext(UserContext);
  const user = userState.isImpersonatingUser ? userState.asUser : userState.user;
  const sessionUsers = sessionStorage.getItem('users');
  const { location } = props;

  useEffect(() => {
    const requestPromises = [getGrailStatusApi(user.Email)];

    // Check if list of users is already in sessionStorage
    // If not, call API to fetch and store
    if (!sessionUsers || sessionUsers === 'undefined') {
      requestPromises.push(getUsersList());
    }

    Promise.all(requestPromises).then(res => {
      if (res[0]) {
        const status = res[0];

        // Get current requestor's grail status.
        userDispatch({ type: 'GET_GRAIL_STATUS', payload: status['Grail Status'] });

        if (res.length > 1 && res[1]) {
          const users = res[1];

          // Fetch leaves summary of the user.
          sessionStorage.setItem('users', JSON.stringify(users.data));
        }
      }
    });

    setHasLoaded(true);

    return () => setHasLoaded(false);
  }, [userState.isImpersonatingUser, userState.asUser]);

  if (!hasLoaded) {
    return <InlineLoading style={{ margin: '1rem 0' }} description="Loading..." />;
  }

  return (
    <RequestContext.Provider value={{ state, dispatch }}>
      <div className="request-page">
        <RequestForm locationState={location.state} />
      </div>
    </RequestContext.Provider>
  );
}

export default RequestFormPage;
