import React from 'react';
import styled from 'styled-components';

const FootContainer = styled.footer`
  padding: 10px 20px;
  border-top: 1px solid #ccc;
`;

function Footer() {
  return (
    <FootContainer>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="mailto:rto.request.system.admin@codeandtheory.com"
      >
        Send feedback or report a problem »
      </a>
    </FootContainer>
  );
}

export default Footer;
