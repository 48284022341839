import React from 'react';
import ReactDOM from 'react-dom';

import { GoogleOAuthProvider } from '@react-oauth/google';

import App from './App';

import './assets/css/app.scss';

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
    <App />
  </GoogleOAuthProvider>,
  document.getElementById('app')
);
