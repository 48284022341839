/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment-timezone';
import { DataTable } from 'carbon-components-react';

import Badges from '../Badges/Badges';
import RTODataTable from '../RTODataTable/RTODataTable';
import RequestEmailAction from './RequestEmailAction';
import RequestCancelAction from './RequestCancelAction';

import './RequestDetailsTable.scss';

import UserContext from '../../../context/userContext';
import LeavesContext from '../../../context/leavesContext';

import * as helper from '../../../utils/CoreHelpers';

const { TableHeader, TableRow, TableCell } = DataTable;

function RequestDetailsTable() {
  const [currentRequests, setCurrentRequests] = useState([]);
  const { state } = useContext(LeavesContext);
  const { state: userState } = useContext(UserContext);
  const { user } = userState;
  const today = moment.utc().startOf('day');
  const tableHeaders = [
    { key: 'requestStatusString', header: 'Status' },
    { key: 'submitted', header: 'Submitted' },
    { key: 'requestType', header: 'Type' },
    { key: 'fromDate', header: 'Date(s)' },
    { key: 'workDays', header: 'Number of Days Off' },
    { key: 'reason', header: 'Reason' },
    { key: 'requestorComments', header: 'Comments' },
    { key: 'actions', header: 'Actions' }
  ];
  const colspanTotal = tableHeaders.length;

  useEffect(() => {
    /*
     * @TODO: Update the part of doing manual sorting of DataTable by submitted date.
     * Currently this is not a Reactive way.
     * The idea is to trigger click event for  the "Submitted" table header to activate
     * the sort. Clicking twice will sort the table in 'DESC' order.
     */
    const customHeaderSort = document.querySelector('button.js-init-sort');

    if (customHeaderSort) {
      customHeaderSort.click();

      setTimeout(() => {
        customHeaderSort.click();
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (state.leavesRequests.length > 0) {
      /* eslint-disable-next-line no-use-before-define */
      const sortedLeavesRequests = doCustomTableInitialSort();

      setCurrentRequests(sortedLeavesRequests);
    }
  }, [state.leavesRequests]);

  function doCustomTableInitialSort() {
    const statuses = [
      'Awaiting Department Lead Decision',
      'Awaiting Vacation Approver Decision',
      'Approved',
      'Acknowledged',
      'Rejected',
      'Cancelled'
    ];
    const leavesRequests = [...state.leavesRequests]
      .sort((a, b) => {
        if (a.submitted > b.submitted) return -1;
        if (a.submitted < b.submitted) return 1;
        return 0;
      })
      .sort((c, d) => {
        if (statuses.indexOf(c.requestStatusString) > statuses.indexOf(d.requestStatusString)) {
          return 1;
        }
        if (statuses.indexOf(c.requestStatusString) < statuses.indexOf(d.requestStatusString)) {
          return -1;
        }
        return 0;
      });

    return leavesRequests;
  }

  function doCustomTableSearch(event) {
    if (state.leavesRequests.length > 0) {
      const currentSortedData = doCustomTableInitialSort();
      const inputValue = event.target.value;

      if (inputValue === '') {
        setCurrentRequests(currentSortedData);

        return;
      }

      const searchables = currentSortedData.filter(request => {
        const fullText = `${request.requestStatusString}
        ${moment(request.submitted).fromNow()}
        ${moment(request.submitted).format('ddd, M/D/YY')}
        ${request.requestRefNum}
        ${request.requestType}
        ${moment(request.fromDate).format('ddd, M/D')}
        ${moment(request.toDate).format('ddd, M/D/YY')}
        ${request.workDays > 1 ? `${request.workDays} work days` : ''}
        ${request.halfDayPeriod !== '' ? `${request.halfDayPeriod.toLowerCase()} only` : ''}
        ${request.reason}
        ${request.requestorComments}`;

        return fullText.toLowerCase().includes(inputValue.toLowerCase());
      });

      setCurrentRequests(searchables);
    }
  }

  const CellRenders = ({ rowId, cellId, cellValue, rawData }) => {
    switch (cellId) {
      case 'requestStatusString':
        return <Badges text={cellValue} />;
      case 'submitted': {
        return (
          <div>
            <p>
              <span>
                {moment
                  .utc(cellValue)
                  .tz(helper.getCurrentLocationTimezone())
                  .format('ddd, MM/DD/YY hh:mm A')}{' '}
                {moment.tz(helper.getCurrentLocationTimezone()).zoneAbbr()}
              </span>
            </p>
            <span>{rawData.requestRefNum}</span>
          </div>
        );
      }
      case 'fromDate':
        return (
          <>
            <div className="table-cell__request-dates">
              {rawData.workDays > 1 ? (
                <>
                  <span>{moment(cellValue).format('ddd, M/D')}</span>
                  <span>&rarr;</span>
                  <span>{moment(rawData.toDate).format('ddd, M/D/YY')}</span>
                </>
              ) : (
                <span>{moment(cellValue).format('ddd, M/D/YY')}</span>
              )}
            </div>

            {rawData.halfDayPeriod ? (
              <div>
                {rawData.halfDayPeriod !== '' && `(${rawData.halfDayPeriod.toLowerCase()} only)`}
              </div>
            ) : (
              ''
            )}
          </>
        );
      case 'workDays':
        return (
          <div className="table-cell__request-days-count">
            {rawData.halfDayPeriod ? <span>0.5</span> : <span>{`${rawData.workDays}`}</span>}
          </div>
        );
      case 'actions': {
        const status = rawData.requestStatusString;
        const toDateParsed = moment.utc(rawData.toDate).startOf('day');
        const isAdminOrHr = user.Role === 'Admin' || user.Role === 'HR';
        /*
         * Show Cancel button only if:
         * Status is 'Pending Approval',
         * or the Request is not of past date already (Approved/Acknowledged requests),
         * unless User is Admin/HR.
         */
        const showCancelButton =
          status.search(/Awaiting/) > -1 ||
          (status.search(/Approved|Acknowledged/) > -1 &&
            (toDateParsed.isSameOrAfter(today, 'day') || isAdminOrHr));

        return (
          <>
            {status.search(/Awaiting/) > -1 && (
              <RequestEmailAction
                requestId={rowId}
                submitted={rawData.submitted}
                historyData={rawData.historyJson}
              />
            )}
            {showCancelButton && <RequestCancelAction requestId={rowId} />}
          </>
        );
      }
      default:
        return cellValue;
    }
  };

  return (
    <RTODataTable
      title="Request Details"
      tableHeaders={tableHeaders}
      tableRows={currentRequests}
      withSorting
      withSearch
      customSearchFunction={event => doCustomTableSearch(event)}
      headersRender={(headers, getHeaderProps) =>
        headers.map(header => {
          if (header.key === 'actions') {
            return <TableHeader key={header.key}>{header.header}</TableHeader>;
          }

          return (
            <TableHeader
              className={header.key === 'fromDate' ? 'js-init-sort' : ''}
              key={header.key}
              {...getHeaderProps({ header })}
            >
              {header.header}
            </TableHeader>
          );
        })
      }
      rowsRender={rows => {
        return rows.length > 0 ? (
          rows.map(row => {
            const rawData = currentRequests.find(data => data.id === row.id);

            if (!rawData) return null;

            const isRejectedOrCancelled =
              rawData.requestStatusString.search(/Rejected|Cancelled/) > -1;
            const isAwaiting = rawData.requestStatusString.search(/Awaiting/) > -1;
            const toDateParsed = moment.utc(rawData.toDate).startOf('day');
            let cellClass = '';

            if (isAwaiting) {
              cellClass = 'rto-data-table__cell--awaiting';
            }

            if (toDateParsed.isBefore(today) || isRejectedOrCancelled) {
              cellClass = 'rto-data-table__cell--past';
            }

            return (
              <TableRow key={row.id}>
                {row.cells.map(cell => {
                  const cellId = cell.id.split(':')[1];
                  let cellValue = cell.value;

                  if (cellId === 'requestStatusString') {
                    if (cellValue.search(/Awaiting/) > -1) {
                      cellValue =
                        cellValue.search(/Vacation/) > -1
                          ? 'Pending Approval - Vacation Approver'
                          : 'Pending Approval - Department Lead';
                    }

                    if (rawData.requestStatusString === 'Cancelled') {
                      cellValue = 'Cancelled';
                    }
                  }

                  return (
                    <TableCell key={cell.id} className={cellClass}>
                      <CellRenders
                        rowId={row.id}
                        cellId={cellId}
                        cellValue={cellValue}
                        rawData={rawData}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={colspanTotal}>No Requests found.</TableCell>
          </TableRow>
        );
      }}
    />
  );
}

export default RequestDetailsTable;
