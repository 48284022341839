import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CaretLeft20 from '@carbon/icons-react/es/caret--left/20';

const StyledBackButton = styled.button`
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
  background: transparent;
  border: none;
  font-size: 14px;
  color: #3186c3;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const BackButton = ({ label }) => {
  const goBack = e => {
    e.preventDefault();
    window.history.back(); // go one step backwards from history stack
  };

  return (
    <StyledBackButton onClick={goBack} type="button">
      <CaretLeft20 fill="#3186c3" />
      <span>{label}</span>
    </StyledBackButton>
  );
};

BackButton.propTypes = {
  label: PropTypes.string.isRequired
};

export default BackButton;
