import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'carbon-components-react';

import './RTOPagination.scss';

const RTOPagination = props => {
  const { totalItems, pageChanged } = props;

  return (
    <div className="rto-pagination">
      <Pagination
        page={1}
        totalItems={totalItems}
        pageSize={10}
        pageSizes={[20, 40, 60, 80, 100]} // hardcoded, items per page
        onChange={data => {
          pageChanged(data);
        }}
      />
    </div>
  );
};

RTOPagination.propTypes = {
  totalItems: PropTypes.number,
  pageChanged: PropTypes.func
};

RTOPagination.defaultProps = {
  totalItems: 0,
  pageChanged: () => {}
};

export default RTOPagination;
