import {
  SET_ACTIVE_USER,
  IMPERSONATE_USER,
  END_IMPERSONATION,
  LOGIN_USER,
  LOGOUT_USER,
  SET_LOGIN_ERROR,
  GET_GRAIL_STATUS
} from './types';

import * as helper from '../utils/CoreHelpers';

const loginUser = (user, state) => {
  return {
    ...state
  };
};

const logoutUser = (user, state) => {
  localStorage.removeItem('isLogged');
  localStorage.removeItem('activeUser');
  helper.removeCookie();

  return {
    ...state,
    isLogged: false,
    user: {},
    isImpersonatingUser: false,
    asUser: {}
  };
};

const setActiveUser = (user, state) => {
  const currentUser = user;
  localStorage.setItem('isLogged', true);
  localStorage.setItem('activeUser', JSON.stringify(currentUser));

  return {
    ...state,
    isLogged: true,
    user: currentUser
  };
};

const impersonateUser = (user, state) => {
  return {
    ...state,
    isImpersonatingUser: true,
    asUser: user
  };
};

const endImpersonation = (user, state) => {
  return {
    ...state,
    isImpersonatingUser: false,
    asUser: {}
  };
};

const setLoginError = (err, state) => {
  return {
    ...state,
    hasLoginError: err.state,
    loginError: err.msg
  };
};

const getGrailStatus = (status, state) => {
  return {
    ...state,
    grailStatus: status
  };
};

export default (state, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return loginUser(action.payload, state);
    case LOGOUT_USER:
      return logoutUser(action.payload, state);
    case SET_ACTIVE_USER:
      return setActiveUser(action.payload, state);
    case IMPERSONATE_USER:
      return impersonateUser(action.payload, state);
    case END_IMPERSONATION:
      return endImpersonation(action.payload, state);
    case SET_LOGIN_ERROR:
      return setLoginError(action.payload, state);
    case GET_GRAIL_STATUS:
      return getGrailStatus(action.payload, state);
    default:
      return state;
  }
};
